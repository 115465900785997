import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  currentDeveloperUser: null,
  currentDeveloperProject: null,
  developerUserProjects: [],
};

const getters = {
  currentDeveloperUser: (state) => state.currentDeveloperUser,
  currentDeveloperProject: (state) => state.currentDeveloperProject,
  developerUserProjects: (state) => state.developerUserProjects,
};

const actions = {
  setCurrentDeveloperUser({ commit }, user) {
    commit('setCurrentDeveloperUser', user);
  },
  getDeveloperUserProjects({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.currentDeveloperUser.userId) {
        api
          .getDeveloperUserProjects(state.currentDeveloperUser.userId)
          .then((response) => {
            const decoded = builder.decode(
              response.body,
              'DeveloperProjectListResponse'
            );
            commit('setDeveloperUserProjects', decoded);
            resolve();
          })
          .catch(() => {
            reject(new Error('Developer Users Error!'));
          });
      } else {
        reject(new Error('No Current Developer User Error!'));
      }
    });
  },
  getDeveloperUserProject({ state, commit }, { projectId }) {
    return new Promise((resolve, reject) => {
      api
        .getDeveloperProjectApplications(
          state.currentDeveloperUser.userId,
          projectId
        )
        .then((response) => {
          const decoded = builder.decode(response.body, 'ProjectResponse');
          commit('setCurrentDeveloperProject', decoded);
          resolve();
        })
        .catch(() => {
          reject(new Error('Developer Project Error!'));
        });
    });
  },
  markDeveloperApplicationAsReviewed({ state, dispatch }, { applicationId }) {
    return new Promise((resolve, reject) => {
      api
        .markDeveloperApplicationAsReviewed(applicationId)
        .then(() => {
          dispatch('getDeveloperUserProject', state.currentDeveloperProject);
          resolve();
        })
        .catch(() => {
          reject(new Error('Developer Application Review Error!'));
        });
    });
  },
};

const mutations = {
  setCurrentDeveloperUser(state, user) {
    state.currentDeveloperUser = user;
  },
  setDeveloperUserProjects(state, { projects }) {
    state.developerUserProjects = projects;
  },
  setCurrentDeveloperProject(state, project) {
    state.currentDeveloperProject = project;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
