import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  user: {},
  userInput: null,
  error: false,
};

const getters = {
  user: (state) => state.user,
  userInput: (state) => state.userInput,
  error: (state) => state.error,
};

const actions = {
  searchUserByEmail({ state, commit }) {
    builder
      .encode(
        { query: 'EMAIL', userinput: state.userInput },
        'AdminSearchUserRequest'
      )
      .then((encoded) => {
        api
          .getUser(encoded)
          .then((response) => {
            const resp = builder.decode(
              response.body,
              'AdminSearchUserResponse'
            );
            commit('setUser', resp);
          })
          .catch(() => commit('setError', true));
      });
  },
  searchUserByPhoneNumber({ state, commit }) {
    builder
      .encode(
        { query: 'PHONE_NUMBER', userinput: state.userInput },
        'AdminSearchUserRequest'
      )
      .then((encoded) => {
        api
          .getUser(encoded)
          .then((response) => {
            const resp = builder.decode(
              response.body,
              'AdminSearchUserResponse'
            );
            commit('setUser', resp);
          })
          .catch(() => commit('setError', true));
      });
  },
  searchUserByUserName({ state, commit }) {
    builder
      .encode(
        { query: 'USERNAME', userinput: state.userInput },
        'AdminSearchUserRequest'
      )
      .then((encoded) => {
        api
          .getUser(encoded)
          .then((response) => {
            const resp = builder.decode(
              response.body,
              'AdminSearchUserResponse'
            );
            commit('setUser', resp);
          })
          .catch(() => commit('setError', true));
      });
  },
};

const mutations = {
  setUserInput(state, { value }) {
    state.userInput = value;
  },
  setUser(state, value) {
    if (value.gender === 0) value.gender = 'Undefined';
    if (value.gender === 1) value.gender = 'Male';
    if (value.gender === 2) value.gender = 'Female';
    if (value.phone_number === '') value.phone_number = 'Undefined';
    state.user = value;
  },
  setError(state, value) {
    state.error = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
