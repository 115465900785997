import Vue from 'vue';
import Vuex from 'vuex';
import login from './login';
import section from './section';
import banTools from './ban_tools';
import superusers from './superusers';
import superuserCoupons from './superusers/coupons';
import nsfw from './nsfw';
import search from './search';
import removePost from './remove_post';
import tags from './tags';
import broadcasts from './broadcasts';
import developerUsers from './developer/developer-users';
import developerUserProjects from './developer/developer-user-projects';
import review from './review';
import wallets from './wallets';
import chatBackgrounds from './chat-backgrounds';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    section,
    broadcasts,
    banTools,
    superusers,
    superuserCoupons,
    nsfw,
    search,
    tags,
    developerUsers,
    developerUserProjects,
    review,
    removePost,
    wallets,
    chatBackgrounds,
  },
});
