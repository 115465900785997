import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  superusers: [],
  expirationTimes: {},
  nextToken: null,
  superusersNotLoaded: true,
};

const getters = {
  superusers: (state) => state.superusers,
  expirationTimes: (state) => state.expirationTimes,
  nextToken: (state) => state.nextToken,
  superusersNotLoaded: (state) => state.superusersNotLoaded,
};

const actions = {
  getSuperusers({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .getSuperusers(state.nextToken)
        .then((response) => {
          const decodedResponse = builder.decode(
            response.body,
            'SuperusersWithExpirationResponse'
          );
          const superusers = decodedResponse.users;
          const expirationTimes = decodedResponse.expirationTimes;
          const newNextToken = decodedResponse.nextToken;
          commit('setSuperusers', { superusers, expirationTimes });
          commit('setNextToken', { newNextToken });
          resolve();
        })
        .catch(() => {
          reject(new Error('Superusers Error!'));
        });
    });
  },
  searchSuperuser({ state, commit }) {
    api.searchSuperuser(state.superuserInput).then((response) => {
      const decodedResponse = builder.decode(
        response.body,
        'SearchUsersResponse'
      );
      const superusers = decodedResponse.users;
      commit('setSearchedSuperusers', { superusers });
    });
  },
  updateExpirationDate({ commit }, { userId, newDate }) {
    commit('updateExpirationDate', { userId, newDate });
  },
  expireSuperuser({ state }, { userId }) {
    let data = {
      userId: userId,
      expirationTimeInMillis: state.expirationTimes[userId].getTime(),
    };
    builder.encode(data, 'SuperuserExpirationTime').then((encoded) => {
      api.expireSuperuser(encoded);
    });
  },
  expireAllSuperusers({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .expireAllSuperusers()
        .then((response) => {
          const superusers = builder.decode(
            response.body,
            'SuperusersWithExpirationResponse'
          );
          commit('setSuperusers', { value: superusers });
          resolve(superusers);
        })
        .catch(() => {
          reject(new Error('Superuser Errors'));
        });
    });
  },
};

const mutations = {
  setSuperusers(state, { superusers, expirationTimes }) {
    if (superusers && superusers.length > 0) {
      superusers.forEach((superuser) => {
        state.superusers.push(superuser);
      });
      expirationTimes.forEach((expirationTime) => {
        const dateString = new Date(expirationTime.expirationTimeInMillis);
        state.expirationTimes[expirationTime.userId] = dateString;
      });
    } else {
      state.superusers = superusers;
    }
  },
  updateExpirationDate(state, { userId, newDate }) {
    state.expirationTimes[userId] = newDate;
  },
  setNextToken(state, { newNextToken }) {
    state.nextToken = newNextToken;
    if (!state.nextToken) {
      state.superusersNotLoaded = false;
    } else {
      state.superusersNotLoaded = true;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
