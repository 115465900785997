import Vue from 'vue';
import { REMOTE_PATHS } from '@/constants/remote-paths.constant';

const getHeaders = () => {
  return {
    Accept: 'application/x-protobuf',
    'X-Auth-Token': localStorage.getItem('xAuthToken'),
  };
};

const postHeaders = () => {
  return {
    Accept: 'application/x-protobuf',
    'Content-Type': 'application/x-protobuf',
    'X-Auth-Token': localStorage.getItem('xAuthToken'),
  };
};

const constructPath = (path, base) => {
  let prefix;
  if (base) {
    prefix = base;
  } else {
    prefix = REMOTE_PATHS.API_PATH;
  }
  return `${prefix}${path}`;
};

const http = {
  _get({ path, params, base }) {
    return Vue.http.get(constructPath(path, base), {
      headers: getHeaders(),
      params,
      responseType: 'arraybuffer',
    });
  },
  _post({ path, body, base }) {
    return Vue.http.post(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'arraybuffer',
    });
  },
  _put({ path, body, base }) {
    return Vue.http.put(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'arraybuffer',
    });
  },
  _patch({ path, body, base }) {
    return Vue.http.patch(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'arraybuffer',
    });
  },
  _delete({ path, base }) {
    return Vue.http.delete(constructPath(path, base), {
      headers: postHeaders(),
      responseType: 'arraybuffer',
    });
  },
  _putImage({ path, body, base, type }) {
    return Vue.http.put(constructPath(path, base), body, {
      headers: { 'Content-Type': type },
    });
  },
};

const proto = {
  getSections() {
    return http._get({ path: '/sections' });
  },
  putSections(sections) {
    return http._put({ path: '/panel/sections', body: sections });
  },
  getSuperusers(nextToken) {
    return http._get({
      path: '/panel/superusers',
      params: { next_token: nextToken },
    });
  },
  putExpirationTime() {
    return http._put({ path: '/' });
  },
  expireSuperuser(body) {
    return http._put({
      path: '/panel/superusers/change_expiration_date',
      body: body,
    });
  },
  expireAllSuperusers() {
    return http._post({ path: '/panel/superusers/expire_all' });
  },
  getUser(userInput) {
    return http._post({
      path: '/get_user',
      body: userInput,
    });
  },
  getTags(nextToken) {
    return http._get({ path: '/tags', params: { next_token: nextToken } });
  },
  getBackgroundData(params) {
    return http._get({ path: `/panel/backgrounds`, params });
  },
  updateBackground(data) {
    return http._put({
      path: `/panel/backgrounds/${data.id}`,
      body: data.encoded,
    });
  },
  removeBackground(id) {
    return http._delete({ path: `/panel/backgrounds/${id}` });
  },
  sendBackground(body) {
    return http._post({ path: '/panel/backgrounds', body });
  },
  updateFileToS3(data) {
    return http._putImage({
      path: '',
      body: data.file,
      base: data.base,
      type: data.type,
    });
  },
  getLinksFromS3(body) {
    return http._post({ path: '/panel/generate_upload_url', body });
  },
  getTagByName(tagInput) {
    return http._get({ path: '/tags', params: { q: tagInput } });
  },
  createNewTag(tag) {
    return http._post({ path: '/tags', body: tag });
  },
  removePost(id) {
    return http._delete({ path: `/panel/posts/${id}` });
  },
  updateTag(tag, index) {
    return http._put({ path: `/tags/${index}`, body: tag });
  },
  getTrendingSuperusers(nextToken) {
    return http._get({
      path: '/panel/superusers/trending',
      params: { next_token: nextToken },
    });
  },
  searchSuperuser(input) {
    return http._get({
      path: '/panel/superusers/search',
      params: { q: input },
    });
  },
  getDeveloperUsers(page) {
    return http._get({ path: '/developer', params: { page: page } });
  },
  getDeveloperUserProjects(userId) {
    return http._get({ path: `/developer/${userId}` });
  },
  getDeveloperProjectApplications(userId, projectId) {
    return http._get({ path: `/developer/${userId}/${projectId}` });
  },
  markDeveloperApplicationAsReviewed(applicationId) {
    return http._post({ path: `/developer/review/${applicationId}` });
  },
  getApplicationsForReview(token) {
    return http._get({
      path: `/developer/review`,
      params: { next_token: token },
    });
  },
  searchSpacesToBan(query) {
    return http._post({ path: '/panel/ban_space/search', body: query });
  },
  fetchWalletStatistics() {
    return http
      ._get({ path: '/panel/wallet_statistics' })
      .then((response) => response.body);
  },
  fetchActiveWalletUsers(params) {
    return http
      ._get({ path: '/panel/active_wallets', params })
      .then((response) => response.body);
  },
  fetchWalletTransactions(id, params) {
    return http
      ._get({ path: `/panel/wallet_transactions/${id}`, params })
      .then((response) => response.body);
  },
  fetchBvnDetails(bvn) {
    return http
      ._get({ path: `/panel/bvn_details/${bvn}` })
      .then((response) => response.body);
  },
  getSuperuserCoupons(params) {
    return http._get({ path: '/panel/superusers/coupons', params });
  },
  createSuperuserCoupon() {
    return http._post({ path: '/panel/superusers/coupons' });
  },
};

export default proto;
