import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  chatBackgroundsList: [],
  chatBackgroundsNextToken: '',
};

const getters = {
  chatBackgroundsList: (state) => state.chatBackgroundsList,
  chatBackgroundsNextToken: (state) => state.chatBackgroundsNextToken,
};

const actions = {
  getBackgrounds({ state, commit }, type) {
    const params = {
      type,
      next_token: state.chatBackgroundsNextToken,
    };
    return api.getBackgroundData(params).then((resp) => {
      const decodedResponse = builder.newDecode(
        resp.body,
        'GetBackgroundListResponse'
      );
      if (decodedResponse) {
        commit('assignBackgrounds', decodedResponse);
      }
      return decodedResponse;
    });
  },
  prepareAttachmentData({ dispatch }, { file, fileName }) {
    return builder
      .encode({ fileName }, 'GeneratePresignedUploadUrlRequest')
      .then((encoded) => {
        return dispatch('getAndUpdateLinksFromS3', {
          encoded,
          file: file,
        });
      });
  },
  addNewBackground(_store, data) {
    builder
      .encode({ background: data }, 'CreateBackgroundRequest')
      .then((encoded) => {
        api.sendBackground(encoded);
      });
  },
  updateBackground(_store, data) {
    builder
      .encode(
        { background: { imageUrl: data.imageUrl, type: data.type } },
        'UpdateBackgroundRequest'
      )
      .then((encoded) => {
        api.updateBackground({ encoded, id: data.id });
      });
  },
  removeBackground({ commit }, id) {
    api.removeBackground(id).then(() => {
      commit('removeBackground', id);
    });
  },
  async getAndUpdateLinksFromS3(_store, { encoded, file }) {
    const linksData = await api
      .getLinksFromS3(encoded)
      .then((resp) =>
        builder.newDecode(resp.body, 'GeneratePresignedUploadUrlResponse')
      );

    const resp = await api
      .updateFileToS3({ file, base: linksData.uploadUrl, type: file.type })
      .then((resp) => resp);
    if (resp) {
      return linksData.downloadUrl;
    }
  },
  removeBackgroundsListData({ commit }) {
    commit('removeBackgroundsListData');
  },
};

const mutations = {
  assignBackgrounds(state, data) {
    state.chatBackgroundsList = state.chatBackgroundsList.concat(
      data.backgrounds
    );
    state.chatBackgroundsNextToken = data.nextToken;
  },
  removeBackground(state, data) {
    const index = state.chatBackgroundsList.findIndex(
      (background) => background.id === data
    );

    if (index !== -1) {
      state.chatBackgroundsList.splice(index, 1);
    }
  },
  removeBackgroundsListData() {
    state.chatBackgroundsList = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
