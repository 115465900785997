import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  reviewNextToken: '',
  reviewApplications: [],
};

const getters = {
  reviewNextToken: (state) => state.reviewNextToken,
  reviewApplications: (state) => state.reviewApplications,
};

const actions = {
  getApplicationsForReview({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .getApplicationsForReview(state.reviewNextToken)
        .then((response) => {
          const applications = builder.decode(
            response.body,
            'DeveloperReviewApplicationsResponse'
          );
          commit('setReviewNextToken', applications.nextToken || '');
          commit('concatReviewApplications', applications);
          resolve(applications);
        })
        .catch(() => {
          reject(new Error('Review Applications Error!'));
        });
    });
  },
  reviewApplication({ state, commit }, { index }) {
    return new Promise((resolve, reject) => {
      const application = state.reviewApplications[index];
      const applicationId =
        application[Object.keys(application)[0]].applicationId;

      api
        .markDeveloperApplicationAsReviewed(applicationId)
        .then(() => {
          commit('spliceApplication', { index });
          resolve();
        })
        .catch(() => {
          reject(new Error('Application Review Error!'));
        });
    });
  },
};

const mutations = {
  setReviewNextToken(state, token) {
    state.reviewNextToken = token;
  },
  spliceApplication(state, { index }) {
    state.reviewApplications.splice(index, 1);
  },
  concatReviewApplications(state, { applications }) {
    state.reviewApplications.push.apply(state.reviewApplications, applications);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
