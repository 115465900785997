import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  usersCount: 0,
  pagesCount: 1,
  page: 0,
  developerUsers: [],
};

const getters = {
  page: (state) => state.page,
  pagesCount: (state) => state.pagesCount,
  usersCount: (state) => state.usersCount,
  developerUsers: (state) => state.developerUsers,
};

const actions = {
  getDeveloperUsers({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.page === state.pagesCount) {
        resolve([]);
      } else {
        api
          .getDeveloperUsers(state.page + 1)
          .then((response) => {
            const users = builder.decode(
              response.body,
              'DeveloperUsersResponse'
            );
            commit('setPage', { pageNumber: state.page + 1 });
            commit('setPagesCount', users);
            commit('setUsersCount', users);
            commit('concatDeveloperUsers', users);
            resolve(users);
          })
          .catch(() => {
            reject(new Error('Developer Users Error!'));
          });
      }
    });
  },
};

const mutations = {
  setPage(state, { pageNumber }) {
    state.page = pageNumber;
  },
  setPagesCount(state, { totalPages }) {
    state.pagesCount = totalPages;
  },
  setUsersCount(state, { totalUsers }) {
    state.usersCount = totalUsers;
  },
  concatDeveloperUsers(state, { developerUsers }) {
    state.developerUsers.push.apply(state.developerUsers, developerUsers);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
