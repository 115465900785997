/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
'use strict';

var $protobuf = require('protobufjs/light');

var $root = (
  $protobuf.roots['default'] ||
  ($protobuf.roots['default'] = new $protobuf.Root())
).addJSON({
  com: {
    nested: {
      newmedia: {
        nested: {
          admin: {
            options: {
              optimize_for: 'LITE_RUNTIME',
              java_generate_equals_and_hash: true,
              swift_prefix: 'Proto_',
            },
            nested: {
              uploads: {
                options: {
                  swift_prefix: '',
                },
                nested: {
                  GeneratePresignedUploadUrlRequest: {
                    fields: {
                      fileName: {
                        type: 'string',
                        id: 1,
                      },
                      directory: {
                        type: 'DirectoryName',
                        id: 2,
                      },
                    },
                    nested: {
                      DirectoryName: {
                        values: {
                          BACKGROUNDS: 0,
                        },
                      },
                    },
                  },
                  GeneratePresignedUploadUrlResponse: {
                    fields: {
                      uploadUrl: {
                        type: 'string',
                        id: 1,
                      },
                      downloadUrl: {
                        type: 'string',
                        id: 2,
                      },
                    },
                  },
                },
              },
              GetMySuperusersResponse: {
                fields: {
                  superUserIds: {
                    rule: 'repeated',
                    type: 'string',
                    id: 1,
                  },
                  users: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                  superusersAdminInfos: {
                    rule: 'repeated',
                    type: 'SuperuserAdminInfo',
                    id: 3,
                  },
                },
              },
              SuperuserAdminInfo: {
                fields: {
                  userId: {
                    type: 'string',
                    id: 1,
                  },
                  allowedToPinPosts: {
                    type: 'bool',
                    id: 2,
                  },
                },
              },
              AdminSearchUserRequest: {
                fields: {
                  query: {
                    type: 'Query',
                    id: 1,
                  },
                  userinput: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Query: {
                    values: {
                      EMAIL: 0,
                      PHONE_NUMBER: 1,
                      USERNAME: 2,
                    },
                  },
                },
              },
              AdminSearchUserResponse: {
                fields: {
                  gender: {
                    type: 'Gender',
                    id: 1,
                  },
                  email: {
                    type: 'string',
                    id: 2,
                  },
                  phoneNumber: {
                    type: 'string',
                    id: 3,
                  },
                  username: {
                    type: 'string',
                    id: 4,
                  },
                  name: {
                    type: 'string',
                    id: 5,
                  },
                  id: {
                    type: 'string',
                    id: 6,
                  },
                },
                nested: {
                  Gender: {
                    values: {
                      UNKNOWN: 0,
                      MALE: 1,
                      FEMALE: 2,
                    },
                  },
                },
              },
              AdminSearchUserError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      INCORECT_PARAMS: 1,
                      NOT_FOUND: 2,
                    },
                  },
                },
              },
              NewTagRequest: {
                fields: {
                  tag: {
                    type: 'timeline_post.Tag',
                    id: 1,
                  },
                },
              },
              UpdateTagRequest: {
                fields: {
                  tag: {
                    type: 'timeline_post.Tag',
                    id: 1,
                  },
                },
              },
              DeveloperUsersResponse: {
                fields: {
                  developerUsers: {
                    rule: 'repeated',
                    type: 'DeveloperUser',
                    id: 1,
                  },
                  pageNumber: {
                    type: 'int32',
                    id: 2,
                  },
                  totalEntries: {
                    type: 'int32',
                    id: 3,
                  },
                  totalPages: {
                    type: 'int32',
                    id: 4,
                  },
                },
                nested: {
                  DeveloperUser: {
                    fields: {
                      userId: {
                        type: 'string',
                        id: 1,
                      },
                      name: {
                        type: 'string',
                        id: 2,
                      },
                      username: {
                        type: 'string',
                        id: 3,
                      },
                      email: {
                        type: 'string',
                        id: 4,
                      },
                      phoneNumber: {
                        type: 'string',
                        id: 5,
                      },
                      birthDateMillis: {
                        type: 'int64',
                        id: 6,
                      },
                      gender: {
                        type: 'string',
                        id: 7,
                      },
                    },
                  },
                },
              },
              DeveloperReviewApplicationsResponse: {
                fields: {
                  applications: {
                    rule: 'repeated',
                    type: 'com.newmedia.developer.Application',
                    id: 1,
                  },
                  nextToken: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
              GetWalletStatisticsResponse: {
                fields: {
                  walletCount: {
                    type: 'uint64',
                    id: 1,
                  },
                  activeWalletCount: {
                    type: 'uint64',
                    id: 2,
                  },
                  totalWalletTransactionsCount: {
                    type: 'uint64',
                    id: 3,
                  },
                  totalMoneyDeposited: {
                    type: 'uint64',
                    id: 4,
                  },
                  totalMoneyWithdrawn: {
                    type: 'uint64',
                    id: 5,
                  },
                  totalMoneyTransferAmount: {
                    type: 'uint64',
                    id: 6,
                  },
                },
              },
              GetActiveWalletUsersResponse: {
                fields: {
                  users: {
                    rule: 'repeated',
                    type: 'AuditedUser',
                    id: 1,
                  },
                  nextToken: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
              AuditedUser: {
                fields: {
                  userId: {
                    type: 'string',
                    id: 1,
                  },
                  fullName: {
                    type: 'string',
                    id: 2,
                  },
                  username: {
                    type: 'string',
                    id: 3,
                  },
                  email: {
                    type: 'string',
                    id: 4,
                  },
                  phone: {
                    type: 'string',
                    id: 5,
                  },
                  bvn: {
                    type: 'string',
                    id: 6,
                  },
                },
              },
              GetUserWalletTransactions: {
                fields: {
                  transactions: {
                    rule: 'repeated',
                    type: 'AuditedTransaction',
                    id: 1,
                  },
                  nextToken: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
              AuditedTransaction: {
                oneofs: {
                  transaction: {
                    oneof: [
                      'walletCharge',
                      'walletWithdrawal',
                      'moneyTransfer',
                    ],
                  },
                },
                fields: {
                  walletCharge: {
                    type: 'AuditedWalletCharge',
                    id: 1,
                  },
                  walletWithdrawal: {
                    type: 'AuditedWalletWithdrawal',
                    id: 2,
                  },
                  moneyTransfer: {
                    type: 'AuditedMoneyTransfer',
                    id: 3,
                  },
                },
              },
              AuditedWalletCharge: {
                fields: {
                  cardName: {
                    type: 'string',
                    id: 1,
                  },
                  cardLast4: {
                    type: 'string',
                    id: 2,
                  },
                  createdAtMillis: {
                    type: 'uint64',
                    id: 4,
                  },
                  amount: {
                    type: 'uint64',
                    id: 5,
                  },
                },
              },
              AuditedWalletWithdrawal: {
                fields: {
                  reipientBankAccount: {
                    type: 'string',
                    id: 1,
                  },
                  createdAtMillis: {
                    type: 'uint64',
                    id: 2,
                  },
                  amount: {
                    type: 'uint64',
                    id: 3,
                  },
                },
              },
              AuditedMoneyTransfer: {
                fields: {
                  recipentAccount: {
                    type: 'string',
                    id: 1,
                  },
                  amount: {
                    type: 'uint64',
                    id: 2,
                  },
                },
              },
              GetBvnDetailsResponse: {
                fields: {
                  firstName: {
                    type: 'string',
                    id: 1,
                  },
                  lastName: {
                    type: 'string',
                    id: 2,
                  },
                  dateOfBirth: {
                    type: 'string',
                    id: 3,
                  },
                },
              },
            },
          },
          camera: {
            options: {
              swift_prefix: 'Camera',
              objc_class_prefix: 'Camera',
            },
            nested: {
              Sticker: {
                fields: {
                  imageUrl: {
                    type: 'string',
                    id: 1,
                  },
                  imageWidth: {
                    type: 'int32',
                    id: 2,
                  },
                  imageHeight: {
                    type: 'int32',
                    id: 3,
                  },
                },
              },
              StickerListResponse: {
                fields: {
                  stickersList: {
                    rule: 'repeated',
                    type: 'Sticker',
                    id: 1,
                  },
                },
              },
              StickerListError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                    },
                  },
                },
              },
            },
          },
          sticker: {
            options: {
              optimize_for: 'LITE_RUNTIME',
              swift_prefix: 'Proto_',
            },
            nested: {
              Sticker: {
                fields: {
                  stickerId: {
                    type: 'string',
                    id: 1,
                  },
                  stickerUrl: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
              StickerPack: {
                fields: {
                  packId: {
                    type: 'string',
                    id: 1,
                  },
                  name: {
                    type: 'string',
                    id: 2,
                  },
                  description: {
                    type: 'string',
                    id: 3,
                  },
                  size: {
                    type: 'uint64',
                    id: 5,
                  },
                  playBundleId: {
                    type: 'string',
                    id: 6,
                  },
                  appstoreBundleId: {
                    type: 'string',
                    id: 7,
                  },
                  imageUrl: {
                    type: 'string',
                    id: 8,
                  },
                  paid: {
                    type: 'bool',
                    id: 9,
                  },
                  new: {
                    type: 'bool',
                    id: 10,
                  },
                  free: {
                    type: 'bool',
                    id: 11,
                  },
                },
              },
              StickerPackWithStickers: {
                fields: {
                  stickerPack: {
                    type: 'StickerPack',
                    id: 1,
                  },
                  stickers: {
                    rule: 'repeated',
                    type: 'Sticker',
                    id: 2,
                  },
                },
              },
              StickerPackWithCount: {
                fields: {
                  stickerPack: {
                    type: 'StickerPack',
                    id: 1,
                  },
                  count: {
                    type: 'int32',
                    id: 2,
                  },
                },
              },
              StickerPacksResponse: {
                fields: {
                  stickerPacks: {
                    rule: 'repeated',
                    type: 'StickerPackWithCount',
                    id: 1,
                  },
                  nextToken: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
              StickerPackResponse: {
                fields: {
                  stickerPack: {
                    type: 'StickerPackWithStickers',
                    id: 1,
                  },
                },
              },
              StickerPackBuyRequest: {
                fields: {
                  packId: {
                    type: 'string',
                    id: 1,
                  },
                  receipt: {
                    type: 'string',
                    id: 2,
                  },
                  store: {
                    type: 'string',
                    id: 3,
                  },
                  signature: {
                    type: 'string',
                    id: 4,
                  },
                },
              },
              StickerPackBuyResponse: {
                fields: {
                  stickerPack: {
                    type: 'StickerPackWithStickers',
                    id: 1,
                  },
                },
              },
              StickerPackBuyError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                    },
                  },
                },
              },
            },
          },
          developer: {
            options: {
              swift_prefix: 'Proto_',
            },
            nested: {
              ApplicationType: {
                values: {
                  ios: 0,
                  android: 1,
                  web: 2,
                },
              },
              MinimalProjectData: {
                fields: {
                  projectId: {
                    type: 'string',
                    id: 1,
                  },
                  applicationTypes: {
                    rule: 'repeated',
                    type: 'ApplicationType',
                    id: 2,
                  },
                  name: {
                    type: 'string',
                    id: 3,
                  },
                  email: {
                    type: 'string',
                    id: 4,
                  },
                },
              },
              AndroidApplication: {
                fields: {
                  applicationId: {
                    type: 'string',
                    id: 1,
                  },
                  applicationClientId: {
                    type: 'string',
                    id: 2,
                  },
                  applicationName: {
                    type: 'string',
                    id: 3,
                  },
                  applicationPackageName: {
                    type: 'string',
                    id: 4,
                  },
                  applicationSignatureSha1: {
                    type: 'string',
                    id: 5,
                  },
                  applicationIconUrl: {
                    type: 'string',
                    id: 6,
                  },
                  applicationReviewed: {
                    type: 'bool',
                    id: 7,
                  },
                  applicationSignaturesSha1: {
                    rule: 'repeated',
                    type: 'string',
                    id: 8,
                  },
                },
              },
              IosApplication: {
                fields: {
                  applicationId: {
                    type: 'string',
                    id: 1,
                  },
                  applicationClientId: {
                    type: 'string',
                    id: 2,
                  },
                  applicationName: {
                    type: 'string',
                    id: 3,
                  },
                  applicationBundleId: {
                    type: 'string',
                    id: 4,
                  },
                  applicationRedirectUri: {
                    type: 'string',
                    id: 5,
                  },
                  applicationIconUrl: {
                    type: 'string',
                    id: 6,
                  },
                  applicationReviewed: {
                    type: 'bool',
                    id: 7,
                  },
                },
              },
              WebApplication: {
                fields: {
                  applicationId: {
                    type: 'string',
                    id: 1,
                  },
                  applicationClientId: {
                    type: 'string',
                    id: 2,
                  },
                  applicationName: {
                    type: 'string',
                    id: 3,
                  },
                  applicationWebsiteUrl: {
                    type: 'string',
                    id: 4,
                  },
                  applicationIconUrl: {
                    type: 'string',
                    id: 5,
                  },
                  applicationReviewed: {
                    type: 'bool',
                    id: 6,
                  },
                },
              },
              Application: {
                oneofs: {
                  applicationData: {
                    oneof: ['android', 'ios', 'web'],
                  },
                },
                fields: {
                  android: {
                    type: 'AndroidApplication',
                    id: 1,
                  },
                  ios: {
                    type: 'IosApplication',
                    id: 2,
                  },
                  web: {
                    type: 'WebApplication',
                    id: 3,
                  },
                },
              },
              CreateAndroidApplication: {
                fields: {
                  applicationName: {
                    type: 'string',
                    id: 1,
                  },
                  applicationPackageName: {
                    type: 'string',
                    id: 2,
                  },
                  applicationSignatureSha1: {
                    type: 'string',
                    id: 3,
                  },
                  applicationIconUrl: {
                    type: 'string',
                    id: 4,
                  },
                },
              },
              CreateIosApplication: {
                fields: {
                  applicationName: {
                    type: 'string',
                    id: 1,
                  },
                  applicationBundleId: {
                    type: 'string',
                    id: 2,
                  },
                  applicationIconUrl: {
                    type: 'string',
                    id: 3,
                  },
                },
              },
              CreateWebApplication: {
                fields: {
                  applicationName: {
                    type: 'string',
                    id: 1,
                  },
                  applicationWebsiteUrl: {
                    type: 'string',
                    id: 2,
                  },
                  applicationIconUrl: {
                    type: 'string',
                    id: 3,
                  },
                },
              },
              DeveloperProjectListResponse: {
                fields: {
                  projects: {
                    rule: 'repeated',
                    type: 'MinimalProjectData',
                    id: 1,
                  },
                },
              },
              CreateProjectRequest: {
                fields: {
                  name: {
                    type: 'string',
                    id: 1,
                  },
                  email: {
                    type: 'string',
                    id: 2,
                  },
                  userId: {
                    type: 'string',
                    id: 3,
                  },
                },
              },
              CreateProjectResponse: {
                fields: {
                  project: {
                    type: 'MinimalProjectData',
                    id: 1,
                  },
                },
              },
              CreateProjectError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                    },
                  },
                },
              },
              DeleteProjectResponse: {
                fields: {
                  project: {
                    type: 'MinimalProjectData',
                    id: 1,
                  },
                },
              },
              DeleteProjectError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      PROJECT_NOT_FOUND: 1,
                    },
                  },
                },
              },
              ProjectResponse: {
                fields: {
                  projectId: {
                    type: 'string',
                    id: 1,
                  },
                  applications: {
                    rule: 'repeated',
                    type: 'Application',
                    id: 2,
                  },
                  email: {
                    type: 'string',
                    id: 3,
                  },
                  projectName: {
                    type: 'string',
                    id: 4,
                  },
                },
              },
              ProjectError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1,
                    },
                  },
                },
              },
              CreateApplicationRequest: {
                oneofs: {
                  applicationData: {
                    oneof: ['ios', 'android', 'web'],
                  },
                },
                fields: {
                  ios: {
                    type: 'CreateIosApplication',
                    id: 1,
                  },
                  android: {
                    type: 'CreateAndroidApplication',
                    id: 2,
                  },
                  web: {
                    type: 'CreateWebApplication',
                    id: 3,
                  },
                },
              },
              CreateApplicationResponse: {
                fields: {
                  application: {
                    type: 'Application',
                    id: 1,
                  },
                },
              },
              CreateApplicationError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1,
                    },
                  },
                },
              },
              UpdateApplicationRequest: {
                fields: {
                  newName: {
                    type: 'string',
                    id: 1,
                  },
                  applicationType: {
                    type: 'ApplicationType',
                    id: 2,
                  },
                },
              },
              UpdateApplicationResponse: {
                fields: {
                  application: {
                    type: 'Application',
                    id: 1,
                  },
                },
              },
              UpdateApplicationError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1,
                    },
                  },
                },
              },
              DeleteApplicationError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1,
                    },
                  },
                },
              },
              WebApplicationInfo: {
                fields: {},
                nested: {
                  Response: {
                    fields: {
                      applicationClientId: {
                        type: 'string',
                        id: 1,
                      },
                      applicationName: {
                        type: 'string',
                        id: 2,
                      },
                      applicationImageUrl: {
                        type: 'string',
                        id: 3,
                      },
                      applicationWebsiteUrl: {
                        type: 'string',
                        id: 4,
                      },
                      projectName: {
                        type: 'string',
                        id: 5,
                      },
                    },
                  },
                  Error: {
                    fields: {
                      code: {
                        type: 'Code',
                        id: 1,
                      },
                      message: {
                        type: 'string',
                        id: 2,
                      },
                    },
                    nested: {
                      Code: {
                        values: {
                          UNKNOWN: 0,
                          APPLICATION_NOT_FOUND: 1,
                          PROJECT_NOT_FOUND: 2,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          timeline_view: {
            options: {
              optimize_for: 'LITE_RUNTIME',
              swift_prefix: 'Proto_',
            },
            nested: {
              PostViewsResponse: {
                fields: {
                  postId: {
                    type: 'string',
                    id: 1,
                  },
                  viewsCount: {
                    type: 'int64',
                    id: 2,
                  },
                },
              },
              PostViewRequest: {
                fields: {
                  postIds: {
                    rule: 'repeated',
                    type: 'string',
                    id: 1,
                  },
                },
              },
            },
          },
          section: {
            options: {
              optimize_for: 'LITE_RUNTIME',
              java_generate_equals_and_hash: true,
              swift_prefix: '',
            },
            nested: {
              GetSectionsInfoResponse: {
                fields: {
                  sectionCount: {
                    type: 'int32',
                    id: 1,
                  },
                  sections: {
                    rule: 'repeated',
                    type: 'Section',
                    id: 2,
                  },
                },
              },
              UpdateSectionDataRequest: {
                fields: {
                  sections: {
                    rule: 'repeated',
                    type: 'Section',
                    id: 1,
                  },
                },
              },
              UpdateSectionDataResponse: {
                fields: {},
              },
              UpdateSectionDataError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                    },
                  },
                },
              },
              Section: {
                fields: {
                  name: {
                    type: 'string',
                    id: 1,
                  },
                  description: {
                    type: 'string',
                    id: 2,
                  },
                  visibleElements: {
                    type: 'int32',
                    id: 3,
                  },
                  elements: {
                    rule: 'repeated',
                    type: 'SectionElement',
                    id: 4,
                  },
                },
              },
              SectionElement: {
                fields: {
                  name: {
                    type: 'string',
                    id: 1,
                  },
                  iconUrl: {
                    type: 'string',
                    id: 2,
                  },
                  subtitle: {
                    type: 'string',
                    id: 3,
                  },
                  description: {
                    type: 'string',
                    id: 4,
                  },
                  androidData: {
                    type: 'AndroidSectionData',
                    id: 6,
                  },
                  iosData: {
                    type: 'IosSectionData',
                    id: 7,
                  },
                },
              },
              AndroidSectionData: {
                oneofs: {
                  link: {
                    oneof: ['launchLink', 'launchApplication'],
                  },
                },
                fields: {
                  launchLink: {
                    type: 'LaunchLink',
                    id: 1,
                  },
                  launchApplication: {
                    type: 'LaunchAndroidApplication',
                    id: 2,
                  },
                },
                nested: {
                  LaunchAndroidApplication: {
                    fields: {
                      packageName: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                },
              },
              IosSectionData: {
                oneofs: {
                  link: {
                    oneof: ['launchLink', 'launchApplication'],
                  },
                },
                fields: {
                  launchLink: {
                    type: 'LaunchLink',
                    id: 1,
                  },
                  launchApplication: {
                    type: 'LaunchIosApplication',
                    id: 2,
                  },
                },
                nested: {
                  LaunchIosApplication: {
                    fields: {
                      iosAppLink: {
                        type: 'string',
                        id: 1,
                      },
                      iosAppIdentifier: {
                        type: 'string',
                        id: 2,
                      },
                    },
                  },
                },
              },
              LaunchLink: {
                fields: {
                  linkUrl: {
                    type: 'string',
                    id: 1,
                  },
                },
              },
            },
          },
          superuser: {
            options: {
              optimize_for: 'LITE_RUNTIME',
              swift_prefix: 'Proto_',
            },
            nested: {
              TrendingSuperusersResponse: {
                fields: {
                  superusersIds: {
                    rule: 'repeated',
                    type: 'string',
                    id: 1,
                  },
                  users: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                  nextToken: {
                    type: 'string',
                    id: 3,
                  },
                },
              },
              FollowingType: {
                values: {
                  NONE: 0,
                  FOLLOWED: 1,
                  SUBSCRIBED_AND_FOLLOWED: 2,
                },
              },
              MySuperuserStatus: {
                fields: {
                  userId: {
                    type: 'string',
                    id: 1,
                  },
                  followingType: {
                    type: 'FollowingType',
                    id: 2,
                  },
                },
              },
              MySuperusersResponse: {
                fields: {
                  status: {
                    rule: 'repeated',
                    type: 'MySuperuserStatus',
                    id: 1,
                  },
                  users: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                },
              },
              FollowSuperuserRequest: {
                fields: {
                  followingType: {
                    type: 'FollowingType',
                    id: 1,
                  },
                },
              },
              FollowSuperuserResponse: {
                fields: {
                  status: {
                    type: 'MySuperuserStatus',
                    id: 1,
                  },
                  users: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                },
              },
              FollowSuperuserError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      SUPERUSER_NOT_FOUND: 1,
                    },
                  },
                },
              },
              SuperuserCouponResponse: {
                fields: {
                  coupon: {
                    rule: 'repeated',
                    type: 'Coupon',
                    id: 1,
                  },
                  nextToken: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
              Coupon: {
                fields: {
                  code: {
                    type: 'string',
                    id: 1,
                  },
                  status: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
              SuperusersWithExpirationResponse: {
                fields: {
                  superuserIds: {
                    rule: 'repeated',
                    type: 'string',
                    id: 1,
                  },
                  users: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                  expirationTimes: {
                    rule: 'repeated',
                    type: 'SuperuserExpirationTime',
                    id: 3,
                  },
                  nextToken: {
                    type: 'string',
                    id: 4,
                  },
                },
              },
              SuperuserExpirationTime: {
                fields: {
                  userId: {
                    type: 'string',
                    id: 1,
                  },
                  expirationTimeInMillis: {
                    type: 'int64',
                    id: 2,
                  },
                },
              },
              SuperusersWithExpirationError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                    },
                  },
                },
              },
              GetSuperuserAdminsRequest: {
                fields: {},
              },
              GetSuperuserAdminsResponse: {
                fields: {
                  adminUserIds: {
                    rule: 'repeated',
                    type: 'string',
                    id: 1,
                  },
                  adminUsers: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                  superuserOwnerIds: {
                    rule: 'repeated',
                    type: 'string',
                    id: 3,
                  },
                },
              },
              GetSuperuserAdminsError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      SUPERUSER_NOT_FOUND: 1,
                      USER_IS_NOT_ADMIN: 2,
                    },
                  },
                },
              },
              AddSuperuserAdminRequest: {
                fields: {
                  userId: {
                    type: 'string',
                    id: 1,
                  },
                },
              },
              AddSuperuserAdminResponse: {
                fields: {
                  adminUsersIds: {
                    rule: 'repeated',
                    type: 'string',
                    id: 1,
                  },
                  adminUsers: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                },
              },
              AddSuperuserAdminError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      SUPERUSER_NOT_FOUND: 1,
                      USER_IS_NOT_ADMIN: 2,
                      NEW_ADMIN_NOT_FOUND: 3,
                    },
                  },
                },
              },
              DeleteSuperuserAdminResponse: {
                fields: {},
              },
              DeleteSuperuserAdminError: {
                fields: {
                  code: {
                    type: 'Code',
                    id: 1,
                  },
                  userMessage: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      SUPERUSER_NOT_FOUND: 1,
                      USER_IS_NOT_ADMIN: 2,
                      SELF_DELETE_NOT_ALLOWED: 3,
                    },
                  },
                },
              },
              ExpirationPayment: {
                fields: {
                  paymentDetails: {
                    rule: 'repeated',
                    type: 'PaymentDetails',
                    id: 1,
                  },
                },
              },
              PaymentDetails: {
                fields: {
                  amount: {
                    type: 'string',
                    id: 1,
                  },
                  institutionCode: {
                    type: 'string',
                    id: 2,
                  },
                  currency: {
                    type: 'string',
                    id: 3,
                  },
                },
              },
              SuperuserProfileUpdateRequest: {
                fields: {
                  name: {
                    type: 'UpdatedName',
                    id: 1,
                  },
                  username: {
                    type: 'UpdatedUsername',
                    id: 2,
                  },
                  accountType: {
                    type: 'UpdatedAccountType',
                    id: 3,
                  },
                  location: {
                    type: 'UpdatedLocation',
                    id: 4,
                  },
                  websiteUrl: {
                    type: 'UpdatedWebsiteUrl',
                    id: 5,
                  },
                  bio: {
                    type: 'UpdatedBio',
                    id: 6,
                  },
                  email: {
                    type: 'UpdatedEmail',
                    id: 7,
                  },
                  superuserPhoneNumber: {
                    type: 'UpdatedSuperuserPhoneNumber',
                    id: 8,
                  },
                  avatarUrl: {
                    type: 'UpdatedAvatar',
                    id: 9,
                  },
                  coverUrl: {
                    type: 'UpdatedCover',
                    id: 10,
                  },
                },
                nested: {
                  UpdatedName: {
                    fields: {
                      name: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedUsername: {
                    fields: {
                      username: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedAccountType: {
                    fields: {
                      accountType: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedLocation: {
                    fields: {
                      location: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedWebsiteUrl: {
                    fields: {
                      websiteUrl: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedBio: {
                    fields: {
                      bio: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedEmail: {
                    fields: {
                      email: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedSuperuserPhoneNumber: {
                    fields: {
                      superuserPhoneNumber: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedAvatar: {
                    fields: {
                      avatarUrl: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  UpdatedCover: {
                    fields: {
                      coverUrl: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                },
              },
              SuperuserProfileUpdateResponse: {
                fields: {
                  superuserProfile: {
                    type: 'user.User',
                    id: 1,
                  },
                },
              },
              SuperuserProfileUpdateError: {
                fields: {
                  code: {
                    type: 'ErrorCode',
                    id: 1,
                  },
                  message: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  ErrorCode: {
                    values: {
                      UNKNOWN: 0,
                      NAME: 1,
                      USERNAME: 2,
                      EMAIL_ALREADY_TAKEN: 3,
                      EMAIL_CAN_NOT_BE_EMPTY: 4,
                      USER_IS_NOT_ADMIN: 5,
                    },
                  },
                },
              },
              AdminSuperusersExpirationResponse: {
                fields: {
                  expirations: {
                    rule: 'repeated',
                    type: 'SuperuserExpirationTime',
                    id: 1,
                  },
                  superusers: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 2,
                  },
                },
              },
              GetSuperuserAccountTypesResponse: {
                fields: {
                  accountTypes: {
                    rule: 'repeated',
                    type: 'string',
                    id: 1,
                  },
                },
              },
              SuperuserProfileCreateRequest: {
                fields: {
                  name: {
                    type: 'string',
                    id: 1,
                  },
                  username: {
                    type: 'string',
                    id: 2,
                  },
                  accountType: {
                    type: 'string',
                    id: 3,
                  },
                  location: {
                    type: 'string',
                    id: 4,
                  },
                  bio: {
                    type: 'string',
                    id: 6,
                  },
                  email: {
                    type: 'string',
                    id: 7,
                  },
                  phoneNumber: {
                    type: 'string',
                    id: 8,
                  },
                  avatarUrl: {
                    type: 'Avatar',
                    id: 9,
                  },
                  coverUrl: {
                    type: 'Cover',
                    id: 10,
                  },
                  coupon: {
                    type: 'Coupon',
                    id: 11,
                  },
                  recaptcha: {
                    type: 'authenticationV2.ReCaptchaToken',
                    id: 12,
                  },
                },
                nested: {
                  Avatar: {
                    fields: {
                      avatarUrl: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  Cover: {
                    fields: {
                      coverUrl: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  Coupon: {
                    fields: {
                      code: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                },
              },
              SuperuserProfileCreateResponse: {
                fields: {
                  superuserProfile: {
                    type: 'user.User',
                    id: 1,
                  },
                },
              },
              SuperuserProfileCreateError: {
                fields: {
                  code: {
                    type: 'ErrorCode',
                    id: 1,
                  },
                  message: {
                    type: 'string',
                    id: 2,
                  },
                },
                nested: {
                  ErrorCode: {
                    values: {
                      UNKNOWN: 0,
                      NAME: 1,
                      USERNAME: 2,
                      EMAIL_ALREADY_TAKEN: 3,
                      EMAIL_CAN_NOT_BE_EMPTY: 4,
                      WRONG_CODE: 6,
                      CODE_REDEEMED: 7,
                      INVALID_RECAPTCHA: 8,
                    },
                  },
                },
              },
              GetSuperuserFollowersResponse: {
                fields: {
                  followers: {
                    rule: 'repeated',
                    type: 'user.User',
                    id: 1,
                  },
                  nextToken: {
                    type: 'string',
                    id: 2,
                  },
                },
              },
            },
          },
          auth: {
            nested: {
              model: {
                options: {
                  swift_prefix: 'Proto_',
                },
                nested: {
                  ApplicationRequest: {
                    fields: {
                      applicationData: {
                        type: 'ApplicationData',
                        id: 4,
                      },
                    },
                  },
                  ApplicationResponse: {
                    fields: {
                      applicationName: {
                        type: 'string',
                        id: 1,
                      },
                      applicationUrl: {
                        type: 'string',
                        id: 2,
                      },
                      applicationAuthenticated: {
                        type: 'bool',
                        id: 3,
                      },
                    },
                  },
                  ApplicationError: {
                    fields: {
                      code: {
                        type: 'ErrorCode',
                        id: 1,
                      },
                      userMessage: {
                        type: 'string',
                        id: 2,
                      },
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          WRONG_APPLICATION_DATA: 1,
                        },
                      },
                    },
                  },
                  AuthRequest: {
                    fields: {
                      scope: {
                        rule: 'repeated',
                        type: 'string',
                        id: 1,
                      },
                      responseType: {
                        type: 'ResponseType',
                        id: 2,
                      },
                      applicationData: {
                        type: 'ApplicationData',
                        id: 4,
                      },
                    },
                    nested: {
                      ResponseType: {
                        values: {
                          UNKNOWN: 0,
                          CODE: 1,
                        },
                      },
                    },
                  },
                  AuthResponse: {
                    fields: {
                      code: {
                        type: 'string',
                        id: 1,
                      },
                    },
                  },
                  AuthError: {
                    fields: {
                      code: {
                        type: 'ErrorCode',
                        id: 1,
                      },
                      userMessage: {
                        type: 'string',
                        id: 2,
                      },
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          WRONG_APPLICATION_DATA: 1,
                        },
                      },
                    },
                  },
                  TokenRequest: {
                    fields: {
                      clientId: {
                        type: 'string',
                        id: 1,
                      },
                      grantType: {
                        type: 'GrantType',
                        id: 2,
                      },
                      code: {
                        type: 'string',
                        id: 3,
                      },
                      refreshToken: {
                        type: 'string',
                        id: 4,
                      },
                    },
                    nested: {
                      GrantType: {
                        values: {
                          UNKNOWN: 0,
                          CODE: 1,
                          REFRESH_TOKEN: 2,
                        },
                      },
                    },
                  },
                  TokenResponse: {
                    fields: {
                      accessToken: {
                        type: 'string',
                        id: 1,
                      },
                      expiresInMillis: {
                        type: 'uint32',
                        id: 2,
                      },
                      refreshToken: {
                        type: 'string',
                        id: 3,
                      },
                    },
                  },
                  TokenError: {
                    fields: {
                      code: {
                        type: 'Code',
                        id: 1,
                      },
                      userMessage: {
                        type: 'string',
                        id: 2,
                      },
                    },
                    nested: {
                      Code: {
                        values: {
                          UNKNOWN: 0,
                          LOGGED_OUT: 1,
                        },
                      },
                    },
                  },
                  CertsResponse: {
                    fields: {
                      certificates: {
                        rule: 'repeated',
                        type: 'Certificate',
                        id: 1,
                      },
                    },
                    nested: {
                      Certificate: {
                        fields: {
                          kid: {
                            type: 'string',
                            id: 1,
                          },
                          pemCertificate: {
                            type: 'string',
                            id: 2,
                          },
                        },
                      },
                    },
                  },
                  ApplicationData: {
                    oneofs: {
                      applicationData: {
                        oneof: ['ios', 'android'],
                      },
                    },
                    fields: {
                      ios: {
                        type: 'Ios',
                        id: 1,
                      },
                      android: {
                        type: 'Android',
                        id: 2,
                      },
                    },
                    nested: {
                      Ios: {
                        fields: {
                          clientId: {
                            type: 'string',
                            id: 1,
                          },
                          redirectUri: {
                            type: 'string',
                            id: 2,
                          },
                          applicationBundleId: {
                            type: 'string',
                            id: 3,
                          },
                        },
                      },
                      Android: {
                        fields: {
                          clientId: {
                            type: 'string',
                            id: 1,
                          },
                          packageName: {
                            type: 'string',
                            id: 2,
                          },
                          applicationSignatureSha1: {
                            type: 'string',
                            id: 3,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  user: {
    options: {
      java_package: 'com.appunite.user.model',
      java_multiple_files: true,
      optimize_for: 'LITE_RUNTIME',
      java_generate_equals_and_hash: true,
      java_outer_classname: 'UserModule',
      swift_prefix: 'Proto_',
    },
    nested: {
      PresenceMessage: {
        oneofs: {
          presence: {
            oneof: ['online', 'offline', 'neverSeen'],
          },
        },
        fields: {
          online: {
            type: 'Online',
            id: 2,
          },
          offline: {
            type: 'Offline',
            id: 3,
          },
          neverSeen: {
            type: 'NeverSeen',
            id: 4,
          },
        },
        nested: {
          Online: {
            fields: {},
          },
          Offline: {
            fields: {
              lastSeenInMillis: {
                rule: 'required',
                type: 'int64',
                id: 1,
              },
            },
          },
          NeverSeen: {
            fields: {},
          },
        },
      },
      User: {
        fields: {
          userId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          name: {
            type: 'string',
            id: 2,
          },
          username: {
            type: 'string',
            id: 3,
          },
          postsCount: {
            type: 'int32',
            id: 4,
          },
          superuser: {
            type: 'Superuser',
            id: 5,
          },
          userBio: {
            type: 'string',
            id: 6,
          },
          avatarUrl: {
            type: 'string',
            id: 7,
          },
          avatarMainColor: {
            type: 'int32',
            id: 8,
          },
          privateAccount: {
            type: 'bool',
            id: 9,
            options: {
              default: false,
            },
          },
          badgeIds: {
            rule: 'repeated',
            type: 'string',
            id: 10,
          },
          presence: {
            type: 'PresenceMessage',
            id: 11,
          },
          isBlogger: {
            type: 'bool',
            id: 12,
          },
        },
        nested: {
          Superuser: {
            fields: {
              DEPRECATEDWebsiteUrl: {
                type: 'string',
                id: 1,
              },
              location: {
                type: 'string',
                id: 2,
              },
              coverUrl: {
                type: 'string',
                id: 3,
              },
              accountType: {
                type: 'string',
                id: 4,
              },
              followersCount: {
                type: 'int32',
                id: 5,
              },
              contactEmail: {
                type: 'string',
                id: 6,
              },
              contactPhoneNumber: {
                type: 'string',
                id: 7,
              },
            },
          },
        },
      },
      UserResponse: {
        fields: {
          user: {
            rule: 'required',
            type: 'user.User',
            id: 1,
          },
        },
      },
      PostSuperusersSharersResponse: {
        fields: {
          userIds: {
            rule: 'repeated',
            type: 'string',
            id: 1,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 2,
          },
        },
      },
      GetUserByUsernameResponse: {
        fields: {
          userId: {
            type: 'string',
            id: 1,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 2,
          },
        },
      },
      GetUserByUsernameError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          userMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              USER_NOT_FOUND: 1,
            },
          },
        },
      },
      GetConferenceUrlResponse: {
        fields: {
          conferenceUrl: {
            type: 'string',
            id: 1,
          },
        },
      },
      GetConferenceUrlError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          userMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              CONFERENCE_URL_NOT_FOUND: 1,
            },
          },
        },
      },
      UsersResponse: {
        fields: {
          users: {
            rule: 'repeated',
            type: 'User',
            id: 1,
          },
        },
      },
      RemoveUserRequest: {
        fields: {
          confirmationEmail: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
        },
      },
      RemoveUserResponse: {
        fields: {},
      },
    },
  },
  timeline_post: {
    options: {
      java_package: 'com.appunite.user.model',
      java_multiple_files: true,
      optimize_for: 'LITE_RUNTIME',
      java_generate_equals_and_hash: true,
      swift_prefix: 'Proto_',
    },
    nested: {
      Tag: {
        fields: {
          name: {
            type: 'string',
            id: 1,
          },
          imageWidth: {
            type: 'int32',
            id: 2,
          },
          imageHeight: {
            type: 'int32',
            id: 3,
          },
          imageUrl: {
            type: 'string',
            id: 4,
          },
          postCount: {
            type: 'uint64',
            id: 5,
          },
          tagId: {
            type: 'string',
            id: 6,
          },
          description: {
            type: 'string',
            id: 7,
          },
          subdescription: {
            type: 'string',
            id: 8,
          },
          coverUrl: {
            type: 'string',
            id: 9,
          },
          trending: {
            type: 'bool',
            id: 10,
          },
          featured: {
            type: 'bool',
            id: 11,
          },
          position: {
            type: 'int32',
            id: 12,
          },
          custom: {
            type: 'bool',
            id: 13,
          },
        },
      },
      TagResponse: {
        fields: {
          tags: {
            rule: 'repeated',
            type: 'Tag',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
        },
      },
      FeedEntry: {
        oneofs: {
          body: {
            oneof: ['post', 'followCandidates', 'trendingTags'],
          },
        },
        fields: {
          post: {
            type: 'Post',
            id: 2,
          },
          followCandidates: {
            type: 'FollowCandidates',
            id: 14,
          },
          trendingTags: {
            type: 'TrendingTags',
            id: 15,
          },
        },
        nested: {
          FollowCandidates: {
            fields: {
              usersIds: {
                rule: 'repeated',
                type: 'string',
                id: 1,
              },
              orientation: {
                type: 'OrientationType',
                id: 2,
              },
            },
            nested: {
              OrientationType: {
                values: {
                  HORIZONTAL: 0,
                  VERTICAL: 1,
                },
              },
            },
          },
          TrendingTags: {
            fields: {
              tagsIds: {
                rule: 'repeated',
                type: 'string',
                id: 1,
              },
              orientation: {
                type: 'OrientationType',
                id: 2,
              },
            },
            nested: {
              OrientationType: {
                values: {
                  HORIZONTAL: 0,
                  VERTICAL: 1,
                },
              },
            },
          },
        },
      },
      Mention: {
        fields: {
          actorId: {
            type: 'string',
            id: 1,
          },
          location: {
            type: 'uint32',
            id: 2,
          },
          length: {
            type: 'uint32',
            id: 3,
          },
        },
      },
      Post: {
        fields: {
          postId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          postCreatorId: {
            type: 'string',
            id: 2,
          },
          postResharerId: {
            type: 'string',
            id: 3,
          },
          publishedAtMillis: {
            type: 'uint64',
            id: 4,
          },
          webUrl: {
            type: 'string',
            id: 5,
          },
          body: {
            type: 'Body',
            id: 6,
          },
          spaceId: {
            type: 'string',
            id: 7,
          },
          yookosId: {
            type: 'string',
            id: 8,
          },
          friendlyUrl: {
            type: 'string',
            id: 9,
          },
          interaction: {
            type: 'Interaction',
            id: 10,
          },
          isBlogPost: {
            rule: 'required',
            type: 'bool',
            id: 11,
          },
          editable: {
            type: 'bool',
            id: 12,
          },
          promoted: {
            type: 'bool',
            id: 13,
          },
        },
        nested: {
          Body: {
            fields: {
              textBody: {
                type: 'string',
                id: 6,
              },
              linkPreview: {
                type: 'LinkPreview',
                id: 16,
              },
              mentions: {
                rule: 'repeated',
                type: 'Mention',
                id: 17,
              },
              images: {
                rule: 'repeated',
                type: 'Image',
                id: 18,
              },
              video: {
                type: 'Video',
                id: 19,
              },
              avatar: {
                type: 'Image',
                id: 20,
              },
              stickers: {
                rule: 'repeated',
                type: 'com.newmedia.sticker.Sticker',
                id: 21,
              },
              kctvVideo: {
                type: 'KctvVideo',
                id: 22,
              },
              hashtags: {
                rule: 'repeated',
                type: 'string',
                id: 23,
              },
              actionUrl: {
                type: 'string',
                id: 24,
              },
            },
          },
          Interaction: {
            fields: {
              text: {
                rule: 'required',
                type: 'string',
                id: 1,
              },
              interactionType: {
                rule: 'required',
                type: 'InteractionType',
                id: 2,
              },
            },
            nested: {
              InteractionType: {
                values: {
                  LIKE: 0,
                  COMMENT: 1,
                  RESHARE: 2,
                },
              },
            },
          },
          LinkPreview: {
            fields: {
              imageUrl: {
                type: 'string',
                id: 1,
              },
              title: {
                type: 'string',
                id: 2,
              },
              websiteUrl: {
                type: 'string',
                id: 3,
              },
              originalUrl: {
                type: 'string',
                id: 4,
              },
              description: {
                type: 'string',
                id: 5,
              },
              type: {
                type: 'LinkPreviewType',
                id: 6,
              },
            },
            nested: {
              LinkPreviewType: {
                values: {
                  SMALL: 0,
                  BIG: 1,
                },
              },
            },
          },
          Video: {
            fields: {
              videoUrl: {
                type: 'string',
                id: 1,
              },
              mimeType: {
                type: 'string',
                id: 2,
              },
              imageWidth: {
                type: 'int32',
                id: 3,
              },
              imageHeight: {
                type: 'int32',
                id: 4,
              },
              imageUrl: {
                type: 'string',
                id: 5,
              },
              videoDuration: {
                type: 'int32',
                id: 6,
              },
            },
          },
          KctvVideo: {
            fields: {
              id: {
                rule: 'required',
                type: 'string',
                id: 1,
              },
              title: {
                rule: 'required',
                type: 'string',
                id: 2,
              },
              description: {
                type: 'string',
                id: 3,
              },
              durationInSeconds: {
                rule: 'required',
                type: 'int32',
                id: 4,
              },
              thumbnailPath: {
                rule: 'required',
                type: 'string',
                id: 5,
              },
              videoPath: {
                rule: 'required',
                type: 'string',
                id: 6,
              },
              categoryId: {
                rule: 'required',
                type: 'string',
                id: 7,
              },
            },
          },
          Image: {
            fields: {
              imageUrl: {
                type: 'string',
                id: 1,
              },
              imageWidth: {
                type: 'int32',
                id: 2,
              },
              imageHeight: {
                type: 'int32',
                id: 3,
              },
            },
          },
        },
      },
      PostNewSuperuserRequest: {
        fields: {
          superuserId: {
            rule: 'required',
            type: 'string',
            id: 3,
          },
        },
      },
    },
  },
  backgrounds: {
    options: {
      swift_prefix: '',
    },
    nested: {
      BackgroundType: {
        values: {
          WEB: 0,
          MOBILE: 1,
        },
      },
      Background: {
        fields: {
          id: {
            type: 'string',
            id: 1,
          },
          imageUrl: {
            type: 'string',
            id: 2,
          },
          type: {
            type: 'BackgroundType',
            id: 4,
          },
          createdAtMillis: {
            type: 'uint64',
            id: 7,
          },
        },
      },
      BackgroundParams: {
        fields: {
          imageUrl: {
            type: 'string',
            id: 1,
          },
          type: {
            type: 'BackgroundType',
            id: 3,
          },
        },
      },
      GetBackgroundListResponse: {
        fields: {
          backgrounds: {
            rule: 'repeated',
            type: 'Background',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
        },
      },
      GetBackgroundResponse: {
        fields: {
          background: {
            type: 'Background',
            id: 1,
          },
        },
      },
      GetBackgroundErrorResponse: {
        fields: {
          code: {
            type: 'int32',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
      },
      CreateBackgroundRequest: {
        fields: {
          background: {
            type: 'BackgroundParams',
            id: 1,
          },
        },
      },
      CreateBackgroundResponse: {
        fields: {
          background: {
            type: 'Background',
            id: 1,
          },
        },
      },
      CreateBackgroundErrorResponse: {
        fields: {
          code: {
            type: 'int32',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
      },
      UpdateBackgroundRequest: {
        fields: {
          background: {
            type: 'BackgroundParams',
            id: 1,
          },
        },
      },
      UpdateBackgroundResponse: {
        fields: {
          background: {
            type: 'Background',
            id: 1,
          },
        },
      },
      UpdateBackgroundErrorResponse: {
        fields: {
          code: {
            type: 'int32',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
      },
    },
  },
  search: {
    options: {
      java_package: 'com.appunite.user.model',
      java_multiple_files: true,
      optimize_for: 'LITE_RUNTIME',
      java_generate_equals_and_hash: true,
      swift_prefix: 'Proto_',
    },
    nested: {
      SearchMentionsResponse: {
        fields: {
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
        },
      },
      SearchUsersResponse: {
        fields: {
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
        },
      },
      SearchSuperusersResponse: {
        fields: {
          superusers: {
            rule: 'repeated',
            type: 'user.User',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
        },
      },
      SearchAllUsersResponse: {
        fields: {
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'following.IsUserFollowedInfo',
            id: 3,
          },
        },
      },
      SearchFollowersResponse: {
        fields: {
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'following.IsUserFollowedInfo',
            id: 3,
          },
        },
      },
      SearchFollowersErrorResponse: {
        fields: {
          code: {
            rule: 'required',
            type: 'Code',
            id: 1,
          },
          errorMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              BLOCKED: 1,
              PRIVATE_ACCOUNT: 2,
            },
          },
        },
      },
      SearchFollowedResponse: {
        fields: {
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'following.IsUserFollowedInfo',
            id: 3,
          },
        },
      },
      SearchFollowedErrorResponse: {
        fields: {
          code: {
            rule: 'required',
            type: 'Code',
            id: 1,
          },
          errorMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              BLOCKED: 1,
              PRIVATE_ACCOUNT: 2,
            },
          },
        },
      },
      SearchPostResponse: {
        fields: {
          feeds: {
            type: 'timeline.FeedsResponse',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'following.IsUserFollowedInfo',
            id: 3,
          },
        },
      },
    },
  },
  timeline: {
    options: {
      java_package: 'com.appunite.user.model',
      java_multiple_files: true,
      optimize_for: 'LITE_RUNTIME',
      java_generate_equals_and_hash: true,
      swift_prefix: 'Proto_',
    },
    nested: {
      PostResponse: {
        fields: {
          post: {
            rule: 'required',
            type: 'timeline_post.Post',
            id: 1,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 2,
          },
          shares: {
            type: 'timeline_share.PostSharesResponse',
            id: 3,
          },
          likes: {
            type: 'timeline_like.PostLikesResponse',
            id: 4,
          },
          comments: {
            type: 'timeline_comment.PostCommentsResponse',
            id: 5,
          },
          spaceInfo: {
            type: 'SpaceShortInfo',
            id: 6,
          },
          views: {
            type: 'com.newmedia.timeline_view.PostViewsResponse',
            id: 7,
          },
        },
      },
      FeedsResponse: {
        fields: {
          feedEntries: {
            rule: 'repeated',
            type: 'timeline_post.FeedEntry',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 5,
          },
          trendingTags: {
            rule: 'repeated',
            type: 'timeline_post.Tag',
            id: 6,
          },
          shares: {
            rule: 'repeated',
            type: 'timeline_share.PostSharesResponse',
            id: 7,
          },
          likes: {
            rule: 'repeated',
            type: 'timeline_like.PostLikesResponse',
            id: 8,
          },
          comments: {
            rule: 'repeated',
            type: 'timeline_comment.PostCommentsResponse',
            id: 9,
          },
          spacesInfo: {
            rule: 'repeated',
            type: 'SpaceShortInfo',
            id: 10,
          },
          views: {
            rule: 'repeated',
            type: 'com.newmedia.timeline_view.PostViewsResponse',
            id: 11,
          },
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'following.IsUserFollowedInfo',
            id: 12,
          },
          bloggers: {
            rule: 'repeated',
            type: 'blogging.Blogger',
            id: 13,
          },
        },
      },
      GetWallResponse: {
        fields: {
          wall: {
            rule: 'required',
            type: 'Wall',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
        },
      },
      GetWallErrorResponse: {
        fields: {
          code: {
            rule: 'required',
            type: 'Code',
            id: 1,
          },
          errorMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              USER_NOT_FOUND: 1,
              PRIVATE_ACCOUNT: 2,
            },
          },
        },
      },
      Wall: {
        fields: {
          posts: {
            rule: 'repeated',
            type: 'timeline_post.Post',
            id: 1,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 2,
          },
          shares: {
            rule: 'repeated',
            type: 'timeline_share.PostSharesResponse',
            id: 3,
          },
          likes: {
            rule: 'repeated',
            type: 'timeline_like.PostLikesResponse',
            id: 4,
          },
          comments: {
            rule: 'repeated',
            type: 'timeline_comment.PostCommentsResponse',
            id: 5,
          },
          views: {
            rule: 'repeated',
            type: 'com.newmedia.timeline_view.PostViewsResponse',
            id: 6,
          },
          bloggers: {
            rule: 'repeated',
            type: 'blogging.Blogger',
            id: 7,
          },
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'following.IsUserFollowedInfo',
            id: 8,
          },
        },
      },
      SpaceShortInfo: {
        fields: {},
      },
      NewPostRequest: {
        fields: {
          body: {
            type: 'timeline_post.Post.Body',
            id: 1,
          },
          shareFacebook: {
            type: 'bool',
            id: 2,
          },
        },
      },
      NewPostError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          userMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              SUPERUSER_ACCOUNT_EXPIRED: 1,
              CONTAINS_RESTRICTED_WORDS: 2,
            },
          },
        },
      },
      UpdatePostRequest: {
        fields: {
          body: {
            type: 'timeline_post.Post.Body',
            id: 1,
          },
          shareFacebook: {
            type: 'bool',
            id: 2,
          },
        },
      },
      UpdatePostError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          userMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              SUPERUSER_ACCOUNT_EXPIRED: 1,
              CONTAINS_RESTRICTED_WORDS: 2,
              NOT_EDITABLE: 3,
            },
          },
        },
      },
      VerifyFacebookUserRequest: {
        fields: {
          facebookToken: {
            type: 'string',
            id: 1,
          },
        },
      },
      VerifyFacebookUserResponse: {
        fields: {},
      },
      VerifyFacebookUserError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              FB_CONNECTION_FAILURE: 1,
              FB_TOKEN_WITHOUT_SCOPES: 2,
              FACEBOOK_BAD_USER: 3,
              FACEBOOK_TOKEN_EXPIRED: 4,
            },
          },
        },
      },
      PinSuperuserPostRequest: {
        fields: {
          postId: {
            type: 'string',
            id: 1,
          },
        },
      },
      PinSuperuserPostResponse: {
        fields: {},
      },
      PinSuperuserPostError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          userMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              POST_IS_NOT_SUPERUSER_POST: 1,
              USER_IS_NOT_AN_ADMIN_OF_THIS_SUPERUSER: 2,
              SUPERUSER_IS_NOT_ALLOWED_TO_PIN_THE_POST: 3,
            },
          },
        },
      },
      PinnedSuperuserPostsResponse: {
        fields: {
          pinnedPostIds: {
            rule: 'repeated',
            type: 'string',
            id: 1,
          },
          posts: {
            rule: 'repeated',
            type: 'timeline_post.Post',
            id: 2,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 3,
          },
          shares: {
            rule: 'repeated',
            type: 'timeline_share.PostSharesResponse',
            id: 4,
          },
          likes: {
            rule: 'repeated',
            type: 'timeline_like.PostLikesResponse',
            id: 5,
          },
          comments: {
            rule: 'repeated',
            type: 'timeline_comment.PostCommentsResponse',
            id: 6,
          },
          views: {
            rule: 'repeated',
            type: 'com.newmedia.timeline_view.PostViewsResponse',
            id: 7,
          },
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'following.IsUserFollowedInfo',
            id: 8,
          },
        },
      },
      UnPinSuperuserPostRequest: {
        fields: {},
      },
      UnPinSuperuserPostResponse: {
        fields: {},
      },
      UnPinSuperuserPostError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          userMessage: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
            },
          },
        },
      },
      LivestreamsResponse: {
        fields: {
          livestreams: {
            rule: 'repeated',
            type: 'Livestream',
            id: 1,
          },
        },
      },
      Livestream: {
        fields: {
          streamId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          streamUrl: {
            rule: 'required',
            type: 'string',
            id: 2,
          },
          ownerId: {
            rule: 'required',
            type: 'string',
            id: 3,
          },
          backgroundUrl: {
            type: 'string',
            id: 4,
          },
        },
      },
      PublicPostsResponse: {
        fields: {
          posts: {
            rule: 'repeated',
            type: 'timeline_post.Post',
            id: 1,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 2,
          },
          nextToken: {
            type: 'string',
            id: 3,
          },
        },
      },
      PostCharactersLimitsResponse: {
        fields: {
          limits: {
            rule: 'repeated',
            type: 'PostCharactersLimit',
            id: 1,
          },
        },
      },
      PostCharactersLimit: {
        fields: {
          userId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          limit: {
            rule: 'required',
            type: 'int64',
            id: 2,
          },
        },
      },
    },
  },
  timeline_share: {
    options: {
      java_package: 'com.appunite.user.model',
      java_multiple_files: true,
      optimize_for: 'LITE_RUNTIME',
      java_generate_equals_and_hash: true,
      swift_prefix: 'Proto_',
    },
    nested: {
      PostSharesResponse: {
        fields: {
          postId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          sharesCount: {
            type: 'int64',
            id: 2,
          },
          sharersCount: {
            type: 'int64',
            id: 3,
          },
          shared: {
            type: 'bool',
            id: 4,
          },
          reposted: {
            type: 'bool',
            id: 5,
          },
          forwarded: {
            type: 'bool',
            id: 6,
          },
        },
      },
      PostSharesDetailsResponse: {
        fields: {
          postId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          sharesUsersIds: {
            rule: 'repeated',
            type: 'string',
            id: 2,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 5,
          },
        },
      },
      ReshareAsSuperuserPostRequest: {
        fields: {},
      },
      ReshareAsSuperuserPostResponse: {
        fields: {},
      },
      ReshareAsSuperuserPostError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              POST_NOT_FOUND: 1,
              SUPERUSER_NOT_FOUND: 2,
              YOU_ARE_NOT_ADMIN_OF_THIS_SUPERUSER: 3,
            },
          },
        },
      },
      UndoReshareAsSuperuserPostResponse: {
        fields: {},
      },
      UndoReshareAsSuperuserPostError: {
        fields: {
          code: {
            type: 'Code',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              POST_NOT_FOUND: 1,
              SUPERUSER_NOT_FOUND: 2,
              YOU_ARE_NOT_ADMIN_OF_THIS_SUPERUSER: 3,
            },
          },
        },
      },
    },
  },
  timeline_like: {
    options: {
      java_package: 'com.appunite.user.model',
      java_multiple_files: true,
      optimize_for: 'LITE_RUNTIME',
      java_generate_equals_and_hash: true,
      swift_prefix: 'Proto_',
    },
    nested: {
      PostLikesResponse: {
        fields: {
          postId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          likesCount: {
            type: 'int64',
            id: 2,
          },
          liked: {
            type: 'bool',
            id: 3,
          },
          likersCount: {
            type: 'int64',
            id: 4,
          },
        },
      },
      PostLikesDetailsResponse: {
        fields: {
          postId: {
            rule: 'required',
            type: 'string',
            id: 2,
          },
          likersUsersIds: {
            rule: 'repeated',
            type: 'string',
            id: 1,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 3,
          },
        },
      },
    },
  },
  timeline_comment: {
    options: {
      java_package: 'com.appunite.user.model',
      java_multiple_files: true,
      optimize_for: 'LITE_RUNTIME',
      java_generate_equals_and_hash: true,
      swift_prefix: 'Proto_',
    },
    nested: {
      CommentMessage: {
        fields: {
          commentId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          createdAtMillis: {
            rule: 'required',
            type: 'uint64',
            id: 2,
          },
          body: {
            type: 'string',
            id: 3,
          },
          commenterId: {
            type: 'string',
            id: 4,
          },
          mentions: {
            rule: 'repeated',
            type: 'timeline_post.Mention',
            id: 5,
          },
        },
      },
      PostCommentsResponse: {
        fields: {
          postId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          commentsCount: {
            type: 'int64',
            id: 2,
          },
          commentersCount: {
            type: 'int64',
            id: 3,
          },
          commented: {
            type: 'bool',
            id: 4,
          },
        },
      },
      CommentsResponse: {
        fields: {
          comments: {
            rule: 'repeated',
            type: 'CommentMessage',
            id: 1,
          },
          nextToken: {
            type: 'string',
            id: 2,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 3,
          },
        },
      },
      CreateCommentRequest: {
        fields: {
          body: {
            type: 'string',
            id: 1,
          },
          mentions: {
            rule: 'repeated',
            type: 'timeline_post.Mention',
            id: 2,
          },
        },
      },
      CreateCommentResponse: {
        fields: {
          comment: {
            rule: 'required',
            type: 'CommentMessage',
            id: 1,
          },
        },
      },
      CreateComment: {
        fields: {},
        nested: {
          Error: {
            fields: {
              code: {
                type: 'Code',
                id: 1,
              },
              message: {
                type: 'string',
                id: 2,
              },
            },
            nested: {
              Code: {
                values: {
                  UNKNOWN: 0,
                  CONTAINS_RESTRICTED_WORDS: 1,
                },
              },
            },
          },
        },
      },
      PostCommentsDetailsResponse: {
        fields: {
          postId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
          commentersUsersIds: {
            rule: 'repeated',
            type: 'string',
            id: 2,
          },
          users: {
            rule: 'repeated',
            type: 'user.User',
            id: 3,
          },
        },
      },
      CommentAdded: {
        fields: {
          comment: {
            rule: 'required',
            type: 'CommentMessage',
            id: 1,
          },
          user: {
            rule: 'required',
            type: 'user.User',
            id: 2,
          },
        },
      },
      CommentDeleted: {
        fields: {
          commentId: {
            rule: 'required',
            type: 'string',
            id: 1,
          },
        },
      },
      CommentBroadcast: {
        oneofs: {
          event: {
            oneof: ['commentAdded', 'commentDeleted'],
          },
        },
        fields: {
          commentAdded: {
            type: 'CommentAdded',
            id: 1,
          },
          commentDeleted: {
            type: 'CommentDeleted',
            id: 2,
          },
        },
      },
    },
  },
  following: {
    options: {
      optimize_for: 'LITE_RUNTIME',
      swift_prefix: 'Proto_',
    },
    nested: {
      IsUserFollowedInfo: {
        fields: {
          userId: {
            type: 'string',
            id: 1,
          },
          isFollowed: {
            type: 'bool',
            id: 2,
          },
        },
      },
      GetFollowedInfoResponse: {
        fields: {
          areUsersFollowedInfo: {
            rule: 'repeated',
            type: 'IsUserFollowedInfo',
            id: 1,
          },
        },
      },
    },
  },
  blogging: {
    options: {
      optimize_for: 'LITE_RUNTIME',
      swift_prefix: 'Proto_',
    },
    nested: {
      Blogger: {
        fields: {
          userId: {
            type: 'string',
            id: 1,
          },
        },
      },
      GetBloggersResponse: {
        fields: {
          bloggers: {
            rule: 'repeated',
            type: 'Blogger',
            id: 1,
          },
        },
      },
    },
  },
  authenticationV2: {
    options: {
      swift_prefix: 'Proto_',
    },
    nested: {
      SignUpRequest: {
        oneofs: {
          signUpCredential: {
            oneof: ['email', 'phoneNumber'],
          },
        },
        fields: {
          clientId: {
            type: 'string',
            id: 1,
          },
          scopes: {
            rule: 'repeated',
            type: 'string',
            id: 2,
          },
          code: {
            type: 'string',
            id: 3,
          },
          token: {
            type: 'string',
            id: 4,
          },
          email: {
            type: 'string',
            id: 5,
          },
          phoneNumber: {
            type: 'PhoneNumber',
            id: 6,
          },
          name: {
            type: 'string',
            id: 7,
          },
          password: {
            type: 'string',
            id: 8,
          },
          username: {
            type: 'string',
            id: 9,
          },
        },
      },
      SignUpResponse: {
        fields: {
          token: {
            type: 'com.newmedia.auth.model.TokenResponse',
            id: 1,
          },
        },
      },
      SignUpError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              PERFORMED_BY_UNAUTHORIZED_APP: 1,
              UNAVAILABLE_SCOPE_REQUESTED: 2,
              INVALID_CODE: 3,
              PHONE_NUMBER_ALREADY_TAKEN: 4,
              EMAIL_ALREADY_TAKEN: 5,
              USERNAME_ALREADY_TAKEN: 6,
              EMAIL_CANNOT_CONTAIN_UPPERCASE_LETTERS: 7,
              INCORRECT_PASSWORD: 8,
            },
          },
        },
      },
      VerifyCodeRequest: {
        oneofs: {
          verifiedCredential: {
            oneof: ['email', 'phoneNumber'],
          },
        },
        fields: {
          code: {
            type: 'string',
            id: 1,
          },
          token: {
            type: 'string',
            id: 2,
          },
          email: {
            type: 'string',
            id: 3,
          },
          phoneNumber: {
            type: 'PhoneNumber',
            id: 4,
          },
        },
      },
      VerifyCodeResponse: {
        fields: {},
      },
      VerifyCodeError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              INVALID_CODE: 1,
            },
          },
        },
      },
      SendVerificationCodeRequest: {
        oneofs: {
          requestData: {
            oneof: ['email', 'phoneNumber'],
          },
        },
        fields: {
          email: {
            type: 'EmailVerificationData',
            id: 1,
          },
          phoneNumber: {
            type: 'PhoneNumber',
            id: 2,
          },
          recaptcha: {
            type: 'ReCaptchaToken',
            id: 3,
          },
        },
      },
      SendVerificationCodeResponse: {
        fields: {
          token: {
            type: 'string',
            id: 1,
          },
        },
      },
      SendVerificationCodeError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              INVALID_PHONE_NUMBER: 1,
              PHONE_NUMBER_ALREADY_TAKEN: 2,
              INVALID_EMAIL: 3,
              EMAIL_ALREADY_TAKEN: 4,
              INVALID_RECAPTCHA: 5,
            },
          },
        },
      },
      LogInRequest: {
        oneofs: {
          method: {
            oneof: ['email', 'phone', 'code', 'facebook', 'apple', 'username'],
          },
        },
        fields: {
          clientId: {
            type: 'string',
            id: 1,
          },
          scopes: {
            rule: 'repeated',
            type: 'string',
            id: 2,
          },
          email: {
            type: 'Email',
            id: 3,
          },
          phone: {
            type: 'Phone',
            id: 4,
          },
          code: {
            type: 'Code',
            id: 5,
          },
          facebook: {
            type: 'Facebook',
            id: 6,
          },
          apple: {
            type: 'Apple',
            id: 7,
          },
          username: {
            type: 'Username',
            id: 8,
          },
        },
        nested: {
          Email: {
            fields: {
              email: {
                type: 'string',
                id: 1,
              },
              password: {
                type: 'string',
                id: 2,
              },
            },
          },
          Phone: {
            fields: {
              phoneNumber: {
                type: 'PhoneNumber',
                id: 1,
              },
              password: {
                type: 'string',
                id: 2,
              },
            },
          },
          Code: {
            fields: {
              code: {
                type: 'string',
                id: 1,
              },
              token: {
                type: 'string',
                id: 2,
              },
            },
          },
          Facebook: {
            fields: {
              facebookToken: {
                type: 'string',
                id: 1,
              },
              facebookUserId: {
                type: 'string',
                id: 2,
              },
            },
          },
          Apple: {
            fields: {
              idToken: {
                type: 'string',
                id: 1,
              },
              rawNonce: {
                type: 'string',
                id: 2,
              },
              name: {
                type: 'string',
                id: 3,
              },
            },
          },
          Username: {
            fields: {
              username: {
                type: 'string',
                id: 1,
              },
              password: {
                type: 'string',
                id: 2,
              },
            },
          },
        },
      },
      LogInResponse: {
        fields: {
          token: {
            type: 'com.newmedia.auth.model.TokenResponse',
            id: 1,
          },
        },
      },
      LogInError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              PERFORMED_BY_UNAUTHORIZED_APP: 1,
              UNAVAILABLE_SCOPE_REQUESTED: 2,
              INVALID_CREDENTIALS: 3,
              INVALID_CODE: 4,
              FACEBOOK_AUTH_ERROR: 5,
              APPLE_AUTH_ERROR: 6,
              INVALID_EMAIL: 7,
              UNCONFIRMED_USER: 8,
              USER_BLOCKED: 9,
            },
          },
        },
      },
      SendLoginVerificationCodeRequest: {
        oneofs: {
          requestData: {
            oneof: ['email', 'phoneNumber'],
          },
        },
        fields: {
          email: {
            type: 'EmailVerificationData',
            id: 1,
          },
          phoneNumber: {
            type: 'PhoneNumber',
            id: 2,
          },
          recaptcha: {
            type: 'ReCaptchaToken',
            id: 3,
          },
        },
      },
      SendLoginVerificationCodeResponse: {
        fields: {
          token: {
            type: 'string',
            id: 1,
          },
        },
      },
      SendLoginVerificationCodeError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              INVALID_PHONE_NUMBER: 1,
              UNKNOWN_PHONE_NUMBER: 2,
              INVALID_EMAIL: 3,
              UNKNOWN_EMAIL: 4,
              INVALID_RECAPTCHA: 5,
            },
          },
        },
      },
      ResetPasswordRequest: {
        fields: {
          password: {
            type: 'string',
            id: 1,
          },
        },
      },
      ResetPasswordResponse: {
        fields: {},
      },
      ResetPasswordError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              INCORRECT_PASSWORD: 1,
            },
          },
        },
      },
      SetSecondaryCredentialRequest: {
        oneofs: {
          verifiedCredential: {
            oneof: ['email', 'phoneNumber'],
          },
        },
        fields: {
          code: {
            type: 'string',
            id: 1,
          },
          token: {
            type: 'string',
            id: 2,
          },
          email: {
            type: 'string',
            id: 3,
          },
          phoneNumber: {
            type: 'PhoneNumber',
            id: 4,
          },
        },
      },
      SetSecondaryCredentialResponse: {
        fields: {},
      },
      SetSecondaryCredentialError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              INVALID_CODE: 1,
              INVALID_EMAIL: 2,
              INVALID_PHONE_NUMBER: 3,
              EMAIL_ALREADY_TAKEN: 4,
              PHONE_NUMBER_ALREADY_TAKEN: 5,
            },
          },
        },
      },
      ValidateUsernameRequest: {
        fields: {
          username: {
            type: 'string',
            id: 1,
          },
        },
      },
      ValidateUsernameResponse: {
        fields: {},
      },
      ValidateUsernameError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              USERNAME_ALREADY_TAKEN: 1,
              RESTRICTED_WORD_USED: 2,
              INVALID_FORMAT: 3,
            },
          },
        },
      },
      GenerateUsernameRequest: {
        fields: {
          name: {
            type: 'string',
            id: 1,
          },
        },
      },
      GenerateUsernameResponse: {
        fields: {
          username: {
            type: 'string',
            id: 1,
          },
        },
      },
      GenerateUsernameError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              RESTRICTED_WORD_USED: 1,
              INVALID_FORMAT: 2,
            },
          },
        },
      },
      PhoneNumber: {
        fields: {
          countryCode: {
            type: 'string',
            id: 1,
          },
          number: {
            type: 'string',
            id: 2,
          },
        },
      },
      EmailVerificationData: {
        fields: {
          email: {
            type: 'string',
            id: 1,
          },
          deeplinkBaseUrl: {
            type: 'string',
            id: 2,
          },
        },
      },
      KingsSuiteAppsResponse: {
        fields: {
          kingsSuiteApps: {
            rule: 'repeated',
            type: 'KingsSuiteApp',
            id: 1,
          },
        },
        nested: {
          IosData: {
            fields: {
              appstoreId: {
                type: 'string',
                id: 1,
              },
              deeplinkUrl: {
                type: 'string',
                id: 2,
              },
            },
          },
          AndroidData: {
            fields: {
              packageName: {
                type: 'string',
                id: 1,
              },
            },
          },
          KingsSuiteApp: {
            fields: {
              bannerUrl: {
                type: 'string',
                id: 1,
              },
              webUrl: {
                type: 'string',
                id: 2,
              },
              iosData: {
                type: 'IosData',
                id: 3,
              },
              androidData: {
                type: 'AndroidData',
                id: 4,
              },
            },
          },
        },
      },
      KingsSuiteAppsError: {
        fields: {
          code: {
            type: 'ErrorCode',
            id: 1,
          },
          message: {
            type: 'string',
            id: 2,
          },
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
            },
          },
        },
      },
      ReCaptchaToken: {
        fields: {
          version: {
            type: 'ReCaptchaVersion',
            id: 1,
          },
          token: {
            type: 'string',
            id: 2,
          },
          platform: {
            type: 'Platform',
            id: 3,
          },
        },
        nested: {
          ReCaptchaVersion: {
            values: {
              V2: 0,
              V3: 1,
            },
          },
          Platform: {
            values: {
              ANDROID: 0,
              IOS: 1,
              WEB: 2,
              IOSV2: 3,
              ANDROIDV2: 4,
            },
          },
        },
      },
    },
  },
});

module.exports = $root;
