import api from '@/api';

const state = {
  entries: [],
  nextToken: null,
  entriesNotLoaded: true,
};

const getters = {
  entries: (state) => state.entries,
  entriesNotLoaded: (state) => state.entriesNotLoaded,
};

const actions = {
  getNsfwPosts({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .getNsfwPosts(state.nextToken)
        .then((response) => {
          commit('setEntries', { value: response.body });
          resolve(response.body);
        })
        .catch(() => {
          reject(new Error('Cannot fetch unsafe posts'));
        });
    });
  },
  banPostAuthor(_store, { userId }) {
    api.banUser({ id: userId }).then(() => {});
  },
  banPostCommunity(_store, { communityId }) {
    api.banCommunity({ id: communityId }).then(() => {});
  },
  deletePost(_store, { postId }) {
    api.adminDeletePost(postId).then(() => {});
  },
  ignoreEntry(_store, { entryId }) {
    api.ignoreNsfwPost(entryId).then(() => {});
  },
};

const mutations = {
  setEntries(state, { value }) {
    value.posts.forEach((p) => {
      state.entries.push(p);
    });
    state.nextToken = value.nextToken;

    if (!value.nextToken || value.posts.length === 0) {
      state.entriesNotLoaded = false;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
