import $root from '@/assets/proto-bundle.js';

const builder = {
  decode: (body, message) => {
    const container = $root.lookup(message);
    return container.toObject(container.decode(new Uint8Array(body)));
  },
  newDecode: (data, messageType) => {
    try {
      const message = $root.lookup(messageType);
      const decodedMessage = message.decode(Buffer.from(data, 'base64'));
      return message.toObject(decodedMessage, {
        enums: String,
        longs: String,
        bytes: String,
        defaults: true,
        arrays: true,
        objects: true,
        oneofs: true,
      });
    } catch (e) {
      /* eslint-disable no-console */
      console.debug(e);
    }
  },

  encode: (body, message) => {
    return new Promise((resolve) => {
      const builder = $root.lookup(message);
      const protMessage = builder.fromObject(body);
      const payload = builder.encode(protMessage);
      const finish = payload.finish();
      resolve(finish);
    });
  },
};

export default builder;
