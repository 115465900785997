import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

Vue.use(Router);

function load(component) {
  return () => import(`@/components/${component}.vue`);
}

export default new Router({
  root: '/',
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: load('auth/Login'),
      beforeEnter(to, from, next) {
        if (
          localStorage.getItem('xAuthToken') &&
          localStorage.getItem('adminUser')
        ) {
          next('/');
        } else {
          next();
        }
      },
    },
    {
      path: '/choose_panel',
      name: 'choosePanel',
      component: load('auth/ChoosePanel'),
    },
    {
      path: '/',
      component: load('Authenticated'),
      beforeEnter(to, from, next) {
        if (
          localStorage.getItem('xAuthToken') &&
          localStorage.getItem('adminUser')
        ) {
          next();
        } else {
          store.dispatch('logout');
        }
      },
      children: [
        {
          path: 'more_tab',
          component: load('more-tab/MoreTab'),
          children: [
            {
              path: '',
              name: 'sections',
              component: load('more-tab/sections/Sections'),
            },
            {
              path: 'new_section',
              name: 'newSection',
              component: load('more-tab/sections/section/SectionDetails'),
            },
            {
              path: ':sectionIndex/edit_section',
              name: 'editSection',
              component: load('more-tab/sections/section/SectionDetails'),
            },
            {
              path: ':sectionIndex/new_element',
              name: 'newElement',
              component: load('more-tab/sections/section/ElementDetails'),
            },
            {
              path: ':sectionIndex/:elementIndex/edit_element',
              name: 'editElement',
              component: load('more-tab/sections/section/ElementDetails'),
            },
          ],
        },
        {
          path: 'ban_words',
          component: load('ban-tools/words/Word'),
          name: 'banWord',
        },
        {
          path: 'ban_user',
          component: load('ban-tools/user/User'),
          name: 'banUser',
        },
        {
          path: 'remove_post',
          component: load('remove-post/RemovePost'),
          name: 'removePost',
        },
        {
          path: 'open_broadcasts',
          component: load('broadcasts/OpenBroadcastsList'),
          name: 'openBroadcasts',
        },
        {
          path: 'superuser_expiration',
          component: load('superusers/Expiration'),
          name: 'superuserExpiration',
        },
        {
          path: 'nsfw',
          component: load('nsfw/Nsfw'),
          name: 'nsfw',
        },
        {
          path: 'search',
          component: load('search/Search'),
          name: 'search',
        },
        {
          path: 'tags',
          component: load('tags/TagsList'),
          children: [
            {
              path: '',
              name: 'tags',
              component: load('tags/Tags'),
            },
            {
              path: ':tagIndex/editTag',
              name: 'editTag',
              component: load('tags/EditTag'),
            },
            {
              path: 'new_tag',
              name: 'newTag',
              component: load('tags/EditTag'),
            },
          ],
        },
        {
          path: 'superuser_coupons',
          component: load('superusers/Coupons'),
          name: 'superuserCoupons',
        },
        {
          path: 'chat_backgrounds',
          component: load('chat-backgrounds/Box'),
          name: 'chatBackgrounds',
        },
        {
          path: 'developer',
          component: load('developer/Developer'),
          children: [
            {
              path: '',
              name: 'developerUsersList',
              component: load('developer/developer-users/DeveloperUsersList'),
            },
            {
              path: ':userId',
              name: 'developerUserProjects',
              component: load(
                'developer/developer-user-projects/DeveloperUserProjects'
              ),
            },
            {
              path: ':userId/:projectId',
              name: 'developerUserProject',
              component: load(
                'developer/developer-user-project/DeveloperUserProject'
              ),
            },
          ],
        },
        {
          path: 'application_review',
          component: load('application-review/ApplicationReview'),
          name: 'applicationReview',
        },
        {
          path: 'wallets',
          component: load('wallets/Wallets'),
          name: 'wallets',
        },
        {
          path: 'wallets/:id',
          component: load('wallets/WalletDetails'),
          name: 'wallet',
        },
      ],
    },
    {
      path: '*',
      beforeEnter(to, from, next) {
        next('/');
      },
    },
  ],
});
