import api from '@/api';

const state = {
  username: null,
  bannedWords: [],
  bannedUsers: [],
  bannedUsersNextToken: null,
  bannedUsersNotLoaded: true,
};

const getters = {
  username: (state) => state.username,
  bannedWords: (state) => state.bannedWords,
  bannedUsers: (state) => state.bannedUsers,
  bannedUsersNotLoaded: (state) => state.bannedUsersNotLoaded,
  bannedUsersNextToken: (state) => state.bannedUsersNextToken,
};

const actions = {
  getBannedUsers({ state, commit }) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (state.bannedUsersNextToken) {
        params = {
          next_token: state.bannedUsersNextToken,
        };
      }

      api
        .getBannedUsers(params)
        .then((response) => {
          commit('setBannedUsers', { users: response.body.records });
          commit('setNextToken', { newNextToken: response.body.next_token });
          resolve();
        })
        .catch(() => {
          reject(new Error('getBannedUsers Error!'));
        });
    });
  },
  unbanUser({ commit }, { userId }) {
    return new Promise((resolve) => {
      api.unbanUser({ id: userId }).then(() => {
        commit('removeUser', { userId });
        resolve();
      });
    });
  },
  banUser({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .banUser({ username: state.username })
        .then(() => {
          commit('setUsername', { value: null });
          resolve();
        })
        .catch(() => {
          reject(new Error('Ban user error'));
        });
    });
  },
  getRestrictedWords({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getRestrictedWords()
        .then((response) => {
          commit('setRestrictedWords', { words: response.body.banned_words });
          resolve();
        })
        .catch(() => {
          reject(new Error('getRestrictedWords Error!'));
        });
    });
  },
  restrictWord({ commit }, { word }) {
    return new Promise((resolve, reject) => {
      api
        .restrictWord({ word })
        .then((response) => {
          commit('addRestrictedWord', { word: response.body });
          resolve();
        })
        .catch(() => {
          reject(new Error('Restrict word error'));
        });
    });
  },
  removeRestrictedWord({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      api
        .removeRestrictedWord(id)
        .then(() => {
          commit('removeRestrictedWord', { id });
          resolve();
        })
        .catch(() => {
          reject(new Error('Remove restricted word error'));
        });
    });
  },
};

const mutations = {
  setUsername(state, { value }) {
    state.username = value;
  },
  setUniqueSpaceName(state, { value }) {
    state.uniqueSpaceName = value;
  },
  setBannedUsers(state, { users }) {
    state.bannedUsers.push.apply(state.bannedUsers, users);
  },
  setRestrictedWords(state, { words }) {
    state.bannedWords = words;
  },
  addRestrictedWord(state, { word }) {
    state.bannedWords.unshift(word);
  },
  removeRestrictedWord(state, { id }) {
    const index = state.bannedWords.findIndex((record) => record.id === id);
    state.bannedWords.splice(index, 1);
  },
  removeUser(state, { userId }) {
    const index = state.bannedUsers.findIndex((user) => user.id === userId);
    state.bannedUsers.splice(index, 1);
  },
  setNextToken(state, { newNextToken }) {
    state.bannedUsersNextToken = newNextToken;
    if (!state.bannedUsersNextToken) {
      state.bannedUsersNotLoaded = false;
    } else {
      state.bannedUsersNotLoaded = true;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
