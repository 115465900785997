import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  superuserCouponsList: [],
  superuserCouponsNextToken: '',
};

const getters = {
  superuserCouponsList: (state) => state.superuserCouponsList,
  superuserCouponsNextToken: (state) => state.superuserCouponsNextToken,
};

const actions = {
  getSuperuserCoupons({ commit, state }) {
    const params = {
      next_token: state.superuserCouponsNextToken,
    };
    return api.getSuperuserCoupons(params).then((resp) => {
      const decodedResponse = builder.newDecode(
        resp.body,
        'SuperuserCouponResponse'
      );
      if (decodedResponse) {
        commit('assignSuperuserCoupons', decodedResponse);
      }
      return decodedResponse;
    });
  },
  createSuperuserCoupon({ commit }) {
    return api.createSuperuserCoupon().then((resp) => {
      const decodedResponse = builder.newDecode(resp.body, 'Coupon');
      if (decodedResponse) {
        commit('addSuperuserCoupon', decodedResponse);
      }
      return decodedResponse;
    });
  },
  clearSuperuserCouponsData({ commit }) {
    commit('removeSuperuserCouponsListData');
  },
};

const mutations = {
  assignSuperuserCoupons(state, data) {
    state.superuserCouponsList.push(...data.coupon);
    state.superuserCouponsNextToken = data.nextToken;
  },
  addSuperuserCoupon(state, data) {
    state.superuserCouponsList.unshift(data);
  },
  removeSuperuserCouponsListData() {
    state.superuserCouponsList = [];
    state.superuserCouponsNextToken = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
