import Vue from 'vue';
import { REMOTE_PATHS } from '@/constants/remote-paths.constant';

const getHeaders = () => {
  return {
    Accept: 'application/json',
    'X-Auth-Token': localStorage.getItem('xAuthToken'),
  };
};

const postHeaders = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Auth-Token': localStorage.getItem('xAuthToken'),
  };
};

const constructPath = (path, base) => {
  let prefix;
  if (base) {
    prefix = base;
  } else {
    prefix = REMOTE_PATHS.API_PATH;
  }
  return `${prefix}${path}`;
};

const http = {
  _get({ path, params, base }) {
    return Vue.http.get(constructPath(path, base), {
      headers: getHeaders(),
      params,
      responseType: 'text',
    });
  },
  _post({ path, body, base }) {
    return Vue.http.post(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'text',
    });
  },
  _put({ path, body, base }) {
    return Vue.http.put(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'text',
    });
  },
  _patch({ path, body, base }) {
    return Vue.http.patch(constructPath(path, base), body, {
      headers: postHeaders(),
      responseType: 'text',
    });
  },
  _delete({ path, base }) {
    return Vue.http.delete(constructPath(path, base), {
      headers: postHeaders(),
      responseType: 'text',
    });
  },
};

const json = {
  getXAuthToken(body) {
    return http._post({ path: '/log_in', body: body });
  },
  getPresignedUrl() {
    return http._post({ path: '/panel/media' });
  },
  presignedUrlFileUpload({ presignedUrl, file, progress }) {
    return Vue.http.put(presignedUrl, file, {
      headers: { 'Content-Type': file.type },
      progress: progress,
    });
  },
  banUser(body) {
    return http._post({ path: '/panel/ban_user', body: body });
  },
  stripeConnect(body) {
    return http._post({
      path: `/panel/institutions/stripe/connect`,
      body: body,
    });
  },
  unbanUser(body) {
    return http._post({ path: '/panel/unban_user', body: body });
  },
  getBannedUsers(params) {
    return http._get({ path: '/panel/banned_users', params });
  },
  restrictWord(body) {
    return http._post({ path: '/panel/banned_words', body: body });
  },
  getRestrictedWords() {
    return http._get({ path: '/panel/banned_words' });
  },
  removeRestrictedWord(id) {
    return http._delete({ path: '/panel/banned_words/' + id });
  },
  banCommunity(body) {
    return http._post({ path: '/panel/ban_space', body: body });
  },
  adminDeletePost(postId) {
    return http._delete({
      path: '/panel/posts/' + postId,
    });
  },
  getOpenBroadcasts() {
    return http._get({ path: '/panel/live_stories' });
  },
  closeOpenBroadcast(superuserId) {
    return http._post({
      path: '/panel/live_stories/' + superuserId + '/close',
    });
  },
  getNsfwPosts(nextToken) {
    return http._get({
      path: '/posts',
      params: { next_token: nextToken },
      base: REMOTE_PATHS.NSFW_PATH,
    });
  },
  ignoreNsfwPost(entryId) {
    return http._delete({
      path: '/posts/' + entryId,
      base: REMOTE_PATHS.NSFW_PATH,
    });
  },
};

export default json;
