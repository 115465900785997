import api from '@/api';
import builder from '@/utils/decode-proto.js';

const { decode } = builder;

const state = {
  walletStatistics: {},
  activeWallets: [],
  activeWalletsNextToken: null,
  walletTransactions: [],
  walletTransactionsNextToken: null,
};

const getters = {
  walletStatistics: (state) => state.walletStatistics,
  activeWallets: (state) => state.activeWallets,
  activeWalletsNextToken: (state) => state.activeWalletsNextToken,
  walletTransactions: (state) => state.walletTransactions,
  walletTransactionsNextToken: (state) => state.walletTransactionsNextToken,
};

const actions = {
  async fetchWalletStatistics({ commit }) {
    try {
      const data = await api.fetchWalletStatistics();
      const response = decode(data, 'GetWalletStatisticsResponse');
      commit('setWalletStatistics', response);
      return { response };
    } catch (data) {
      return { error: data };
    }
  },
  async fetchActiveWalletUsers({ commit }) {
    try {
      const params = {};
      if (state.activeWalletsNextToken)
        params.next_token = state.activeWalletsNextToken;
      const data = await api.fetchActiveWalletUsers(params);
      const response = decode(data, 'GetActiveWalletUsersResponse');
      commit('setActiveWallets', response);
      return { response };
    } catch (data) {
      return { error: data };
    }
  },
  async fetchWalletTransactions({ commit }, id) {
    try {
      const params = {};
      if (state.walletTransactionsNextToken)
        params.next_token = state.walletTransactionsNextToken;
      const data = await api.fetchWalletTransactions(id, params);
      const response = decode(data, 'GetUserWalletTransactions');
      commit('setWalletTransactions', response);
      return { response };
    } catch (data) {
      return { error: data };
    }
  },
  async fetchBvnDetails(bvn) {
    try {
      const data = await api.fetchBvnDetails(bvn);
      const response = decode(data, 'GetBvnDetailsResponse');
      return { response };
    } catch (data) {
      return { error: data };
    }
  },
};

const mutations = {
  setWalletStatistics(state, walletStatistics) {
    state.walletStatistics = walletStatistics;
  },
  setActiveWallets(state, { users = [], nextToken }) {
    state.activeWallets = state.activeWallets.concat(users);
    state.activeWalletsNextToken = nextToken;
  },
  setWalletTransactions(state, { transactions, nextToken }) {
    state.walletTransactions = state.walletTransactions.concat(transactions);
    state.walletTransactionsNextToken = nextToken;
  },
  clearActiveWallets(state) {
    state.activeWallets = [];
    state.activeWalletsNextToken = null;
  },
  clearWalletTransactions(state) {
    state.walletTransactions = [];
    state.walletTransactionsNextToken = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
