import Vue from 'vue';
import api from '@/api';
import builder from '@/utils/decode-proto.js';
import { isNonNegativeInteger } from '@/utils/number.helper';

const state = {
  sectionsData: {
    sectionCount: 0,
    sections: [],
  },
};

const getters = {
  element:
    (state) =>
    ({ sectionIndex, elementIndex }) => {
      const section = state.sectionsData.sections[sectionIndex];
      if (section && section.elements && isNonNegativeInteger(elementIndex)) {
        return section.elements[elementIndex];
      }
      return null;
    },
  section:
    (state) =>
    ({ sectionIndex }) =>
      state.sectionsData.sections[sectionIndex],
  sections: (state) => state.sectionsData.sections,
  sectionCount: (state) => state.sectionsData.sectionCount,
};

const actions = {
  getSections({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getSections()
        .then((response) => {
          const sectionsData = builder.decode(
            response.body,
            'GetSectionsInfoResponse'
          );
          commit('setSections', sectionsData);
          resolve();
        })
        .catch(() => {
          reject(new Error('Sections Error!'));
        });
    });
  },
  updateSectionElements({ commit }, { index, value }) {
    commit('updateSectionElements', { index, value });
  },
  addSection({ commit }, { name, description, visibleElements }) {
    commit('addSection', { name, description, visibleElements, elements: [] });
  },
  updateSection(
    { commit },
    { sectionIndex, name, description, visibleElements }
  ) {
    commit('updateSection', {
      sectionIndex,
      name,
      description,
      visibleElements,
    });
  },
  removeSection({ commit }, { sectionIndex }) {
    commit('removeSection', { sectionIndex });
  },
  addElement({ commit }, { sectionIndex, element }) {
    commit('addElement', { sectionIndex, element });
  },
  updateElement({ commit }, { sectionIndex, elementIndex, element }) {
    commit('updateElement', { sectionIndex, elementIndex, element });
  },
  removeElement({ commit }, { section, element }) {
    commit('removeElement', { section, element });
  },
  saveSections({ state }) {
    builder
      .encode(
        { sections: state.sectionsData.sections },
        'UpdateSectionDataRequest'
      )
      .then((encoded) => {
        api.putSections(encoded).then(() => {});
      });
  },
};

const mutations = {
  setSections(state, sectionsData) {
    Vue.set(state, 'sectionsData', sectionsData);
  },
  removeElement(state, { section, element }) {
    state.sectionsData.sections[section].elements.splice(element, 1);
  },
  removeSection(state, { sectionIndex }) {
    state.sectionsData.sections.splice({ sectionIndex }, 1);
  },
  updateSectionElements(state, { index, value }) {
    Vue.set(state.sectionsData.sections[index], 'elements', value);
  },
  updateSections(state, { value }) {
    Vue.set(state.sectionsData, 'sections', value);
  },
  addSection(state, section) {
    state.sectionsData.sectionCount = state.sectionsData.sectionCount + 1;
    state.sectionsData.sections.push(section);
  },
  addElement(state, { sectionIndex, element }) {
    if (!state.sectionsData.sections[sectionIndex].elements) {
      state.sectionsData.sections[sectionIndex].elements = [];
    }
    state.sectionsData.sections[sectionIndex].elements.push(element);
  },
  updateElement(state, { sectionIndex, elementIndex, element }) {
    Vue.set(
      state.sectionsData.sections[sectionIndex].elements,
      elementIndex,
      element
    );
  },
  updateSection(state, { sectionIndex, name, description, visibleElements }) {
    Vue.set(state.sectionsData.sections[sectionIndex], 'name', name);
    Vue.set(
      state.sectionsData.sections[sectionIndex],
      'description',
      description
    );
    Vue.set(
      state.sectionsData.sections[sectionIndex],
      'visibleElements',
      visibleElements
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
