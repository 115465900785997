import api from '@/api';
import builder from '@/utils/decode-proto.js';

const state = {
  tags: [],
  tagsNextToken: null,
  tagsNotLoaded: true,
  tagInput: null,
  tagError: false,
};

const getters = {
  tagInput: (state) => state.tagInput,
  tags: (state) => state.tags,
  tagsNotLoaded: (state) => state.tagsNotLoaded,
  tagsNextToken: (state) => state.tagsNextToken,
  tag:
    (state) =>
    ({ tagIndex }) => {
      if (state.tags[tagIndex]) {
        return state.tags[tagIndex];
      }
      return null;
    },
};

const actions = {
  getTags({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .getTags(state.tagsNextToken)
        .then((response) => {
          const decodedResponse = builder.decode(response.body, 'TagResponse');
          const tags = decodedResponse.tags;
          const newNextToken = decodedResponse.nextToken;
          commit('setTags', { tags });
          commit('setTagNextToken', { newNextToken });
          resolve();
        })
        .catch(() => {
          reject(new Error('Tags Error!'));
        });
    });
  },
  searchTagByName({ state, commit }) {
    api.getTagByName(state.tagInput).then((response) => {
      const decodedResponse = builder.decode(response.body, 'TagResponse');
      const tags = decodedResponse.tags;
      commit('setSearchedTags', { tags });
    });
  },
  addTag({ commit }, tag) {
    builder.encode(tag, 'NewTagRequest').then((encoded) => {
      api
        .createNewTag(encoded)
        .then((response) => {
          const decodedResponse = builder.decode(response.body, 'TagResponse');
          const tag = decodedResponse.tags[0];
          commit('addNewTag', { tag: tag });
        })
        .catch(() => {
          commit('setTagError', true);
        });
    });
  },
  updateTag({ state, commit }, data) {
    builder.encode({ tag: data.tag }, 'UpdateTagRequest').then((encoded) => {
      api
        .updateTag(encoded, state.tags[data.tagIndex].tagId)
        .then((response) => {
          const decodedResponse = builder.decode(response.body, 'TagResponse');
          const tag = decodedResponse.tags[0];
          commit('addNewTag', { tag: tag, index: data.tagIndex });
        })
        .catch(() => {
          commit('setTagError', true);
        });
    });
  },
};

const mutations = {
  setTagInput(state, { value }) {
    state.tagInput = value;
  },
  setTags(state, { tags }) {
    tags.forEach((tag) => {
      state.tags.push(tag);
    });
  },
  addNewTag(state, { tag, index }) {
    if (index >= 0) {
      state.tags[index] = tag;
    } else {
      state.tags.unshift(tag);
    }
  },
  setSearchedTags(state, { tags }) {
    if (tags) {
      state.tags = tags;
    } else {
      state.tags = [];
    }
  },
  setTagNextToken(state, { newNextToken }) {
    state.tagsNextToken = newNextToken;
    if (!state.tagsNextToken) {
      state.tagsNotLoaded = false;
    } else {
      state.tagsNotLoaded = true;
    }
  },
  setTagError(state, value) {
    state.tagError = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
