import Vue from 'vue';
import VueResource from 'vue-resource';
import VueMoment from 'vue-moment';
import VTooltip from 'v-tooltip';
import Toastr from 'vue-toastr';
import VueImg from 'v-img';
import Vuex from 'vuex';
import VueProgressBar from 'vue-progressbar';
import App from './App';
import router from './router';
import store from './store';

Vue.use(Vuex);
Vue.use(VueMoment);
Vue.use(VueResource);
Vue.use(VueProgressBar, {
  color: '#bffaf3',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '1s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
});
Vue.use(VTooltip);
Vue.use(Toastr, {
  defaultProgressBar: false,
  defaultClassNames: ['animated', 'slideInDown'],
});
Vue.use(VueImg, { sourceButton: true });

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: (h) => h(App),
});
