const getRemotePaths = () => {
  const defaults = {
    development: {
      API_PATH: 'https://connect-kc.appunite.net/admin/api',
      KINGSCONFERENCE_API_PATH: 'https://kingsconference.appunite.com',
      KINGSCONFERENCE_TOKEN: 'secretAPITokenStaging',
      NSFW_PATH: 'https://connect-kc.appunite.net/api/nsfw',
      OLD_ADMIN_LINK: 'https://admin-old.staging.kingsch.at',
      KPAY_GOODS_SERVICES: 'https://stg-admin.kingspay-gs.com/login-admin',
    },

    staging: {
      API_PATH: 'https://connect-kc.appunite.net/admin/api',
      KINGSCONFERENCE_API_PATH: 'https://kingsconference.appunite.com',
      KINGSCONFERENCE_TOKEN: 'secretAPITokenStaging',
      NSFW_PATH: 'https://connect-kc.appunite.net/api/nsfw',
      OLD_ADMIN_LINK: 'https://admin-old.staging.kingsch.at',
      KPAY_GOODS_SERVICES: 'https://stg-admin.kingspay-gs.com/login-admin',
    },

    production: {
      API_PATH: 'https://connect.kingsch.at/admin/api',
      KINGSCONFERENCE_API_PATH: 'https://conference.kingsch.at',
      KINGSCONFERENCE_TOKEN:
        '6lajLEoBUDAN_qD1ZLYYJzGRyrauBH1ukU-R8mD2VXvTu2W1kNktqlNX-eQ5hmjdeMMOvLgnty7g08C_NtEtFrl2UBJ5B0-BQLmu-RDyntkdG2sFnykYWCAfEj3XXCVhvbuf8axIWdpci52Me9BWqWK4e3zVAkLCyKh02ziTHiIIq0PKvn0NUKAH3YKxQIWb1FCLZV74M1d',
      NSFW_PATH: 'https://connect.kingsch.at/api/nsfw',
      OLD_ADMIN_LINK: 'https://admin-old.kingsch.at',
      KPAY_GOODS_SERVICES: 'https://admin.kingspay-gs.com/login-admin',
    },
  };

  const ENV =
    window.ENV !== '$ENV'
      ? JSON.parse(window.ENV)
      : {
          // for local use only
          ...defaults[process.env.NODE_ENV],
        };

  return {
    ...ENV,
  };
};

export const REMOTE_PATHS = getRemotePaths();
