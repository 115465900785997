import api from '@/api';
import router from '@/router';

const state = {
  admin: JSON.parse(localStorage.getItem('adminUser')),
};

const getters = {
  admin: (state) => state.admin,
};

const actions = {
  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .getXAuthToken(params)
        .then((response) => {
          const admin = {
            last_name: response.body.last_name,
            first_name: response.body.first_name,
            avatar: response.body.avatar,
          };
          commit('setAdminUser', admin);
          commit('setXAuthToken', response.body.auth_token);
          resolve();
        })
        .catch(() => {
          reject(new Error('Invalid Credentials!'));
        });
    });
  },
  logout({ commit }) {
    commit('removeAdminUser');
    router.push({ name: 'choosePanel' });
  },
};

const mutations = {
  setAdminUser(state, user) {
    localStorage.setItem('adminUser', JSON.stringify(user));
    state.admin = user;
  },
  removeAdminUser() {
    localStorage.removeItem('adminUser');
    localStorage.removeItem('xAuthToken');
  },
  setXAuthToken(_state, xAuthToken) {
    localStorage.setItem('xAuthToken', xAuthToken);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
