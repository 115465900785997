import api from '@/api';

const state = {
  openBroadcasts: [],
};

const getters = {
  openBroadcasts: (state) => state.openBroadcasts,
};

const actions = {
  getOpenBroadcasts({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getOpenBroadcasts()
        .then((response) => {
          commit('setOpenBroadcasts', { broadcasts: response.body.broadcasts });
          resolve();
        })
        .catch(() => {
          reject(new Error('Get open broadcasts failed'));
        });
    });
  },
  closeBroadcast({ commit }, { broadcast }) {
    return new Promise((resolve) => {
      api.closeOpenBroadcast(broadcast.superuser_id).then(() => {
        commit('removeClosedBroadcast', { id: broadcast.id });
        resolve();
      });
    });
  },
};

const mutations = {
  setOpenBroadcasts(state, { broadcasts }) {
    state.openBroadcasts = broadcasts;
  },
  removeClosedBroadcast(state, { id }) {
    const index = state.openBroadcasts.findIndex((record) => record.id === id);
    state.openBroadcasts.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
