import api from '@/api';
// import builder from '@/utils/decode-proto.js';

const actions = {
  removePost({ commit }, data) {
    return api
      .removePost(data)
      .then((response) => {
        return response;
      })
      .catch(() => commit('setError', true));
  },
};

const mutations = {};

export default {
  actions,
  mutations,
};
